import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ysAdmin from "ys-admin";
import '@/filter/index'
import config from "@/config/index";

Vue.config.productionTip = false
Vue.use(ysAdmin, {
  platFormType: 5,
  axiosBaseUrl: '/web',//axios.default.baseUrl
  axiosHeaders: ['token','orgId'],//axios的请求头，如需要header带token，先把token值存入sessionStorage且key为token，必须一样;
  uploadFileUrl: config.baseURL + '/resource/UploadFile',
  getFileUrl: config.baseURL + '/resource/GetFile/',
  iviewConfig: {
    transfer: true,
    capture: false,
  }
});

window.less.modifyVars({
  '@component-background': ysAdmin.color.componentBackground,
  '@border-base-color': ysAdmin.color.borderBaseColor,
  '@background-background': ysAdmin.color.backgroundColor,
  '@text-color': ysAdmin.color.textColor,
  '@primary-color': ysAdmin.color.primaryColor,
})
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

import Vue from 'vue'
import VueRouter from 'vue-router'
import Main from '../views/Main.vue'
// import routerContainer from '@/views/routerContainer.vue'
import config from '@/config'
import store from "@/store/index";
import ysAdmin, {clearPending, connectSocket} from "ys-admin";

Vue.use(VueRouter)

const routes = [
  {path: '/login', name: 'login', meta: {title: '登录', index: '0', hideInMenu: true}, component: () => import('../views/login/Login.vue')},
  {path: '/index', name: 'enter', meta: {title: '系统入口', index: '2', hideInMenu: true}, component: (resolve) => require(['ys-admin/src/components/ys-enter'], resolve)},
  {path: '/sign/:id/:orgName', name: 'eventSign', meta: {title: '签到', index: '3', hideInMenu: true}, component: () => import('../views/task/detail/members/sign.vue')},
  {path: '/', name: 'index', redirect: '/main'},
  {
    path: '/main',
    name: 'main',
    redirect: '/main/workStation',
    component: Main,
    meta: {title: '系统首页', index: '1',},
    children: []
  },
  // //路由不要写在这个后面
  // {
  //   path: '*', name: '404', meta: {hideInMenu: true}, component: () => import('../components/404')
  // },
]

// 声明路由实例
const initRouter = () => new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

// 初始化路由实例
const router = initRouter()

// 重置路由实例, 用于身份验证失败时亦或者退出登录时需重新登录清空当前的路由列表防止重新登录后重复添加路由
export function resetRouter() {
  const newRouter = initRouter()
  router.matcher = newRouter.matcher
}

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return (originalPush.call(this, location)).catch((err) => err)
};

router.beforeEach((to, from, next) => {
      ysAdmin.LoadingBar.start();
      clearPending();
      const token = store.getters.token;
      const userInfo = store.getters.userInfo;
      if (!token) {
        if (config.withOutLogin.indexOf(to.name) < 0) {
          next({
            name: 'login' // 跳转到登录页
          })
        } else {

          // 未登陆且要跳转的页面是登录页
          next() // 跳转
        }
      } else {
        if (to.name === 'login') {
          // 已登录且要跳转的页面是登录页
          next({
            name: 'main' // 跳转到home页
          })
        } else {
          // 判断如果是从login页面过来的则加载webSocket
          if (from.name === 'login') {
            if (token && userInfo.org_id) {
              const wsHost = 'ter-test.us-smart.cn' // ws建立链接地址,然后根据数据中的url来区分订阅的消息
              const types1 = {
                url: '/subscribe/org', // 订阅机构的推送消息
                token: '', // 当前用户token
                body: '', // 当前机构id
              }
              const types2 = {
                url: '/subscribe/mine', // 订阅我的推送消息
                token: '', // 当前用户token
              }
              types1.token = types2.token = token
              types1.body = userInfo.org_id
              connectSocket(wsHost, {types1: types1, types2: types2})
            }
          }
          next()
        }
      }
      // 退出登录需要关闭webSocket连接
      if (to.name === 'login' && window.webSocket) {
        window.webSocket.close()
      }
    }
);
router.afterEach((to) => {
  ysAdmin.LoadingBar.finish();
  let title = '';
  if (to.meta.title) {
    title = (to.meta.title || '') + ' - ' + config.title;
  }
  document.title = title;
});
export default router

<template>
  <div>
    <Spin fix v-if="loading">
      <Icon type="ios-loading" size="35" class="demo-spin-icon-load"></Icon>
      <div>加载中，请稍后......</div>
    </Spin>
    <router-view v-else></router-view>
  </div>
</template>
<script>
import {getDict, checkTypeList} from "@/api/common";
import {mapActions, mapMutations, mapGetters} from 'vuex'
import config from "@/config/index";
import ysAdmin, {connectSocket} from "ys-admin";

export default {
  name: 'app',
  data() {
    return {
      loading: true
    }
  },
  created() {
    getDict({noPending: true}).then(res => {
      if (res.code === 200) {
        let baseData = res.data;
        baseData['隐患回复状态'] = [
          {name: '通过', value: 4, color: '', code: ''},
          {name: '通过', value: 3, color: '', code: ''},
          {name: '已整改', value: 2, color: '', code: ''},
          {name: '驳回', value: 1, color: '', code: ''},
          {name: '回复', value: 0, color: '', code: ''},
        ]
        this.setBaseData(res.data);
      }
    })
    // 初始化(或者刷新后)页面重新加载，需要重新订阅一下webSocket
    if(this.token && this.userInfo.org_id && window.sessionStorage.getItem('webSocketInfo')) {
      const {host} = JSON.parse(window.sessionStorage.getItem('webSocketInfo'))
      const {data} = JSON.parse(window.sessionStorage.getItem('webSocketInfo'))
      connectSocket(host, data)
    }
    this.getCheckType()
  },
  mounted() {
    config.colorTheme = ysAdmin.colorTheme;
    this.getHeight();
    if (this.token) this.getMenuList();
    this.setConfig(config);
    window.addEventListener('resize', this.onWindowResize, false);
    window.setTimeout(() => {
      this.loading = false
    }, 500)
  },
  computed: {
    ...mapGetters(['token', 'userInfo'])
  },
  methods: {
    ...mapMutations(['setBaseData', 'setHeight', 'setConfig', 'setCheckType']),
    ...mapActions(['getMenuList']),
    getHeight() {
      this.setHeight(document.documentElement.clientHeight)
    },
    // 服务类型
    async getCheckType() {
      checkTypeList({}).then(res => {
        if (res.code === 200) {
          let data = (res.data || []).map(item => {
            item.label = item.name;
            item.children = item.children.map(sub => {
              sub.label = sub.name;
              return sub
            })
            return item
          })
          this.setCheckType(data)
        }
      })
    },
    onWindowResize() {
      let timer = null;
      window.clearTimeout(timer);
      timer = window.setTimeout(() => {
        this.getHeight();
      }, 100)
    },
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onWindowResize, false);
  }
}
</script>
<style lang="less" scoped>
.demo-spin-icon-load {
  animation: ani-demo-spin 1s linear infinite;
}

@keyframes ani-demo-spin {
  from {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>

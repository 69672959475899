<template>
  <div :class="classes">
    <Spin fix v-show="loading">
      <Icon type="ios-loading" class="spin-icon-load" size="35"></Icon>
      <div>加载中，请稍后......</div>
    </Spin>
    <div v-if="error" :class="errorCls">
      <template v-if="$slots.error"></template>
      <template v-else>
        <div>
          <Icon type="ios-albums-outline" size="100" color="#cccccc"/>
          <p>加载失败，请重试或联系管理员！</p>
          <Button type="primary" shape="circle" icon="ios-refresh" @click="onError">重试</Button>
        </div>
      </template>
    </div>
    <template v-else>
      <div :class="headerCls">
        <div :class="headerTopCls">
          <div :class="`${headerTopCls}-icon`">
            <ys-image :width="40" :height="40" :src="image" style="vertical-align: middle" v-if="image"/>
            <Icon :type="icon||'ios-help-circle-outline'" size="40" style="vertical-align: middle" v-else/>
          </div>
          <div :class="`${headerTopCls}-detailName`">{{ name }}</div>
          <slot name="status"></slot>
          <div :class="`${headerTopCls}-btn`">
            <slot name="btn" v-if="$slots.btn||pageListThis.multiBtn.length===0"></slot>
            <div v-else>
              <template v-for="(item,index) in pageListThis.multiBtn">
                <template v-if="Array.isArray(item.children)">
                  <ButtonGroup :key="index">
                    <Button
                        v-for="(subItem,subIndex) in item.children"
                        type="primary"
                        :disabled="subItem.disabled"
                        v-show="subItem.show===undefined&&subItem.type!=='detail'?true:subItem.show&&(subItem.isHidden===false||subItem.isHidden===''||subItem.isHidden===undefined)" 
                        @click="btnClick(index,subIndex)"
                        :icon="item.hiddenBtnIcon===false?subItem.icon:''"
                        :key="subIndex">{{ subItem.title }}
                    </Button>
                  </ButtonGroup>
                </template>
                <template v-else>
                  <Button
                      type="primary"
                      :key="index"
                      @click="btnClick(index)"
                      v-show="item.show===undefined&&item.type!=='detail'?true:item.show"
                      :icon="item.hiddenBtnIcon?'':item.icon"
                      :disabled="item.disabled">{{ item.title }}
                  </Button>
                </template>
              </template>
            </div>
          </div>
        </div>
        <ys-cell-group inline :gutter="4" :border="false">
          <slot name="brief"></slot>
        </ys-cell-group>
      </div>
      <div :class="detailMainCls">
        <slot name="main" v-if="$slots.main"></slot>
        <Split v-model="detailSplit" min="600px" max="250px" v-else>
          <div slot="left" :class="`${detailMainCls}-mainLeft`">
            <slot name="main-left"></slot>
          </div>
          <div slot="right" :class="`${detailMainCls}-mainRight`">
            <slot name="main-right"></slot>
          </div>
        </Split>
      </div>
    </template>
  </div>
</template>

<script>
const prefixCls = 'ys-detail';
import ysCellGroup from '../../components/ys-cell/ys-cell-goup'

export default {
  name: "detailInfo",
  components: {ysCellGroup},
  inject: {
    pageListThis:{
      default:()=>{
        return {
          multiBtn:[],
        }
      }
    }
  },
  props: {
    error: {type: Boolean, default: false},
    split: {type: Number, default: 0.75},
    loading: {type: Boolean, default: false},
    image: {type: String, default: ''},
    name: {type: String, default: ''},
    icon: {type: String, default: 'ios-help-circle'}
  },
  watch: {},
  computed: {
    classes() {
      return prefixCls
    },
    headerCls() {
      return [`${prefixCls}-header`]
    },
    errorCls() {
      return [`${prefixCls}-error`]
    },
    headerTopCls() {
      return [`${this.headerCls}-top`]
    },
    detailMainCls() {
      return [`${prefixCls}-detailMain`]
    }
  },
  data() {
    return {
      detailSplit: 0.75
    }
  },
  mounted() {
    this.detailSplit = this.split;
  },
  methods: {
    btnClick(index, subIndex) {
      if (subIndex !== undefined) {
        this.pageListThis.multiBtn[index].children[subIndex].click()
      } else {
        this.pageListThis.multiBtn[index].click()
      }
    },
    close() {
      console.log(this)
    },
    onError() {
      this.$emit('on-error');
    },
  }
}
</script>

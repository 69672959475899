<template>
  <!--  v-viewer-->
  <div class="images" v-viewer :class="`${classCls} ${classCls}-${type}-box`">
    <template v-if="showFile">
      <div :class="`${classCls}-`+'list ' + type" v-for="(item,index) in uploadList" :key="item.uid||item.id" @click="show">
        <template v-if="item.status === 'finished'">
          <div :class="`${classCls}-`+'list-file'" :title="item.fileName">
            <Icon type="md-close-circle" size="23" :class="`${classCls}-`+'list-file-del'" @click="handleRemove(index)" title="删除" v-if="showDel"/>
            <div class="fileList">
              <YSImage :src="item.url" width="46" height="46" :showModal="false" v-if="!item.suffix||imgTypeList.indexOf(item.suffix)>=0||picture"></YSImage>
              <YSImage :src="fileType[item.suffix]" :showModal="false" width="46" height="46" v-else/>
              <div class="info" v-if="type!=='default'">
                <p class="title">{{ item.fileName }}</p>
                <p class="addTime">{{ item.createdAt }}</p>
              </div>
              <a @click="downFile(item)" href="javascript:" class="downFile" title="下载" v-if="type!=='default'&&showDown">
                <Icon type="md-cloud-download"/>
                下载
              </a>
            </div>
          </div>
        </template>
        <template v-else>
          <Progress :percent="item.percentage" hide-info></Progress>
        </template>
      </div>
    </template>
    <Upload
        :key="key"
        ref="upload"
        :disabled="disabled"
        :class="!$slots.default?`${classCls}-`+'list ' + type:''"
        :default-file-list="defaultList"
        :show-upload-list="false"
        :on-error="handleError"
        :on-success="handleSuccess"
        :format="format"
        :max-size="maxSize"
        :on-format-error="handleFormatError"
        :on-exceeded-size="handleMaxSize"
        :before-upload="handleBeforeUpload"
        :multiple="multiple"
        v-show="limit===0||limit>this.uploadList.length"
        :action="action"
        :data="data"
        :headers="headers">
      <div :class="`${classCls}-`+type" v-if="!$slots.default">
        <Icon :type="!loading?'ios-camera':'ios-loading'" size="30" :class="{'spin-icon-load':loading}" style="vertical-align: middle;"></Icon>
      </div>
      <slot v-else></slot>
    </Upload>
  </div>
</template>
<script>

import {formatDate} from "../../lib/index";
import Compressor from 'compressorjs';
import download from "downloadjs"

const prefixCls = 'ys-upload';

export default {
  name: 'UploadFile',
  components: {
    YSImage: () => import('../ys-image/index.vue')
  },
  props: {
    echo: {type: Boolean, default: true},
    showFile: {type: Boolean, default: true},
    multiple: {type: Boolean, default: true},//多选文件
    showDown: {type: Boolean, default: true},
    showDel: {type: Boolean, default: true},
    picture: {type: Boolean, default: false},
    disabled: {type: Boolean, default: false},//是否禁用
    hiddenUploadBtn: {type: Boolean, default: false},
    showView: {type: Boolean, default: true},
    limit: {type: Number, default: 0},
    maxSize: {type: Number, default: 1024 * 5 * 10},
    listType: {type: String, default: 'line'},//default、line/*暂无用*/
    type: {type: String, default: 'default'},//default、halfList、fullList
    viewAction: {type: String, default: ''},
    action: {type: String, default: '', require: true},
    defaultList: {type: Array, default: () => []},//默认已上传的文件列表
    headers: {
      type: Object, default: () => {
        return {}
      }
    },
    data: {
      type: Object, default: () => {
        return {}
      }
    },
    format: {type: Array, default: () => []},
  },
  data() {
    return {
      fileType: {
        AVI: require('../../assets/images/AVI.png'),
        CDR: require('../../assets/images/CDR.png'),
        CSS: require('../../assets/images/CSS.png'),
        DOC: require('../../assets/images/DOC.png'),
        DOCX: require('../../assets/images/DOCX.png'),
        PDF: require('../../assets/images/PDF.png'),
        MOV: require('../../assets/images/MOV.png'),
        HTML: require('../../assets/images/HTML.png'),
        MP3: require('../../assets/images/MP3.png'),
        MP4: require('../../assets/images/MP4.png'),
        PHP: require('../../assets/images/PHP.png'),
        PPT: require('../../assets/images/PPT.png'),
        PPTX: require('../../assets/images/PPTX.png'),
        EPS: require('../../assets/images/EPS.png'),
        PSD: require('../../assets/images/PSD.png'),
        RAR: require('../../assets/images/RAR.png'),
        TTF: require('../../assets/images/TTF.png'),
        TXT: require('../../assets/images/TXT.png'),
        XLS: require('../../assets/images/XLS.png'),
        XLSX: require('../../assets/images/XLSX.png'),
        ZIP: require('../../assets/images/ZIP.png'),
      },
      imgTypeList: ['JPG', 'GIF', 'PNG', 'JPEG'],
      uploadList: [],
      loading: false,
      key: new Date().getTime(),
      uploadNum: 0,
    }
  },
  watch: {
    'defaultList': function () {
      this.$nextTick(() => {
        this.uploadList = this.$refs.upload.fileList;
        this.getSuffix();
      })
    }
  },
  computed: {
    classCls() {
      return prefixCls
    },
  },
  mounted() {
    this.uploadList = this.$refs.upload.fileList;
    this.getSuffix();
  },
  methods: {
    downFile(item) {
      const request = new XMLHttpRequest()
      request.open('GET', item.url)
      request.responseType = 'blob'
      request.onreadystatechange = () => {
        if (request.readyState === XMLHttpRequest.DONE && request.status === 200) {
          console.log(request.response)
          download(request.response, item.fileName)
        }
      }
      request.send()
    },
    getSuffix() {
      let splitAry = [];
      this.uploadList.forEach(item => {
        item.name = item.fileName
        item.url = this.$getFileUrl + item.id
        if (item.fileName) {
          splitAry = item.fileName.split('.')
          item.suffix = item.suffix || splitAry[splitAry.length - 1].toUpperCase();
        }
      })
    },
    show() {
      const viewer = this.$el.querySelector('.images') ? this.$el.querySelector('.images').$viewer : ''
      if (viewer) viewer.show()
    },
    handleRemove(index) {
      this.$refs.upload.fileList.splice(index, 1);
      this.$emit('update:defaultList', this.$refs.upload.fileList);
      this.$emit('on-success', this.$refs.upload.fileList);
    },
    handleError(error, file, fileList) {
      this.$emit('on-error', error, file, fileList);
    },
    handleSuccess(res, file) {
      const {data} = file.response;
      file.id = data ? data.id : 0;
      file.fileName = data ? data.fileName : '';
      file.createdAt = data ? data.createdAt : formatDate(new Date(), 'yyyy-MM-dd hh:mm:ss');
      file.url = this.$getFileUrl + file.id
      this.uploadNum--;
      if (this.uploadNum === 0) {
        this.loading = false;
        this.$emit('update:defaultList', this.$refs.upload.fileList);
        this.$emit('on-success', this.$refs.upload.fileList, res, file);
      }

    },
    handleFormatError() {
      if (this.format.length > 0) {
        this.$Notice.warning({
          title: '提示',
          desc: '文件格式仅限' + this.format.join('，') + '！'
        });
        return false
      }
    },
    handleMaxSize() {
      this.$Notice.warning({
        title: '提示',
        desc: '文件大小不能超过' + this.maxSize / 1024 + 'MB！'
      });
    },
    handleBeforeUpload(file) {
      const check = this.$refs.upload.fileList.length < this.limit || this.limit === 0;
      if (!check) {
        this.$Notice.warning({
          title: '提示',
          desc: '文件数量不能超过' + this.limit + '个！'
        });
        return false;
      }
      this.$emit('on-before');
      const splitAry = file.name.split('.');
      const suffix = splitAry[splitAry.length - 1].toUpperCase();
      if (this.format.length > 0 && this.format.indexOf(suffix.toLowerCase()) === -1) {
        return
      }else {
        this.handleFormatError();//文件格式不在可上传文件格式范围内
      } 
      if (file.type.indexOf('image') >= 0) {
        new Compressor(file, {
          quality: 0.8,
          maxWidth: 3000,
          maxHeight: 3000,
          success: (result) => {
            let targetFile = new File([result], result.name, {type: result.type,});
            this.uploadNum++;
            this.loading = true;
            this.$refs.upload.post(targetFile)
          },
          error: () => {
            this.$Notice.error({
              title: '提示',
              desc: '图片错误，请检查图片！'
            });
          }
        })
        return false;
      } else {
        this.uploadNum++;
        this.loading = true;
        return true;
      }
    }
  },
}
</script>
<style scoped lang="less">
.downFile {
  flex-basis: 55px;
}
</style>

<template>
  <div :class="classCls" :style="{'width':width?width+'px':'auto','height':height?height+'px':'auto'}">
    <span v-if="treeData.length <= 0">{{ emptyText }}</span>
    <draggable
        filter=".ys-tree-item-content-arrow"
        v-model="treeData"
        :disabled="!draggable"
        :group="draggableSkipLevel?'tree':0"
        @start="onStart"
        :item-key="nodeKey"
        animation="300"
        @end="onEnd"
        dragClass="dragClass"
        ghostClass="ghostClass"
        chosenClass="chosenClass"
        :class="treeItemBoxCls"
        v-else>
      <tree-item
          :indent="indent"
          v-for="(item) in treeData"
          :nodeKey="nodeKey"
          :draggableSkipLevel="draggableSkipLevel"
          :defaultExpandAll="defaultExpandAll"
          :draggable="draggable"
          :checkedType="checkedType"
          :render="render"
          :showCheckbox="showCheckbox"
          :highlightCurrent="highlightCurrent"
          @on-start="onItemStart"
          @on-hover="onHover"
          @on-end="onItemEnd"
          @on-select-change="selectChange"
          @on-toggle-expand="toggleExpand"
          @on-checkbox-change="toggleCheck"
          :key="item[nodeKey]"
          :itemData="item"></tree-item>
    </draggable>
  </div>
</template>
<script>
import treeItem from "./tree-item";

const prefixCls = 'ys-tree';
import draggable from "vuedraggable";

export default {
  name: 'YsTree',
  components: {draggable, treeItem},
  props: {
    data: {type: Array, default: () => []},
    defaultCheckedKeys: {type: Array, default: () => []},//默认勾选的key的数据
    defaultSelectKeys: {type: [String, Number], default: ''},//默认选中的key，单个
    defaultExpandedKeys: {type: Array, default: () => []},//默认展开的节点的 key 的数组
    accordion: {type: Boolean, default: false},//是否每次只打开一个同级树节点展开
    checkedType: {type: String, default: 'halfWeak'},//勾选节点的方式，'strictly':严格遵循父子互相关联，子全选，父才选中，否则半选或者不选中,'halfWeak':子选中其中一个父比选中,'weak',父子不互相关联]
    draggableSkipLevel: {type: Boolean, default: false},//拖拽的方式，'false':不可跨级拖拽，'true':可跨级拖拽]
    showCheckbox: {type: Boolean, default: false},//节点是否可被选择
    defaultExpandAll: {type: Boolean, default: false},//是否默认展开所有节点
    highlightCurrent: {type: Boolean, default: true},//是否高亮当前选中节点。
    draggable: {type: Boolean, default: false},//是否开启拖拽节点功能
    expandOnClickNode: {type: Boolean, default: true},//是否在点击节点的时候展开或者收缩节点
    checkOnClickNode: {type: Boolean, default: false},//是否在点击节点的时候选中节点
    indent: {type: Number, default: 20},//相邻级节点间的水平缩进，单位为像素
    width: {type: Number, default: null},
    height: {type: Number, default: null},
    render: {type: Function},
    emptyText: {type: String, default: '暂无数据！'},
    nodeKey: {type: String, default: 'id'},//每个树节点用来作为唯一标识的属性，整棵树应该是唯一的
  },
  computed: {
    classCls() {
      return prefixCls;
    },
    treeItemBoxCls() {
      return `${prefixCls}-box`;
    },
  },
  data() {
    return {
      treeData: [],
      treeMap: new Map(),
      selectKey: '', // 选择(鼠标点击选中的当前节点)
      expandKeys: [], // 展开(被展开的节点)
      checkedKeys: [], // 选中(被勾选中的节点)
    }
  },
  mounted() {
    this.initExpandKeys();
    this.getTreeData();
  },
  methods: {
    onHover(e) {
      this.$emit('on-hover', e);
    },
    forceUpdateTree() {
      this.initExpandKeys();
      this.getTreeData();
    },
    getCheckedNodes() {
      let data = []
      for (const value of this.treeMap) {
        data.push(value[1]);
      }
      return data.filter(item => item.checked)
    },
    initExpandKeys() {
      this.expandKeys = [];
      const deepDoTree = (target) => {
        return target.forEach(({...item}) => {
          if (item.expand || this.defaultExpandAll) this.expandKeys.push(item[this.nodeKey]);
          if (item.children) {
            item.children = deepDoTree(item.children);
          }
        })
      }
      this.treeData = deepDoTree(this.data);
    },
    getTreeData() {
      this.expandKeys = this.defaultExpandAll ? [] : this.defaultExpandedKeys;
      this.selectKey = this.defaultSelectKeys;
      const deepDoTree = (target, _curIndentLevel = 0) => {
        return target.map(({...item}) => {
          item.expand = this.defaultExpandAll ? true : !this.expandKeys.indexOf(item[this.nodeKey]) >= 0;
          item.selected = item[this.nodeKey] === this.selectKey;
          item.checked = this.defaultCheckedKeys.indexOf(item[this.nodeKey]) >= 0 || item.checked || false;
          item.indeterminate = false;
          item._curIndentLevel = _curIndentLevel;
          item.children = item.children || [];
          if (item.children) {
            item.children = deepDoTree(item.children, _curIndentLevel + 1);
          }
          this.treeMap.set(item[this.nodeKey], item)
          return item
        })
      };
      this.treeData = deepDoTree(this.data);

      //默认选中时需要从新触发下选中改变事件
      if (this.defaultCheckedKeys.length > 0) {
        const checkKeys = [];
        const checkDataList = [];
        this.treeMap.forEach((key, value) => {
          if (key.checked) {
            checkKeys.push(value)
            checkDataList.push(key)
          }
        })
        this.$emit('on-checkbox-change', null, null, checkKeys, checkDataList)
      }
    },
    onItemEnd(oldData, newData) {
      this.$emit('on-draggable-end', oldData, newData);
    },
    onItemStart(data) {
      this.$emit('on-draggable-start', data);
    },
    onEnd(data) {
      const {oldIndex, newIndex} = data;
      this.$emit('on-draggable-end', this.treeData[oldIndex], this.treeData[newIndex]);
    },
    onStart(data) {
      const {oldIndex} = data;
      this.$emit('on-draggable-start', this.treeData[oldIndex]);
    },
    selectChange(data) {
      this.selectKey = data[this.nodeKey] === this.selectKey ? '' : data[this.nodeKey];
      const item = this.selectKey ? data : {}
      this.getTreeData();
      this.$emit('on-select-change', item)
      if (this.expandOnClickNode) this.toggleExpand(data)
      if (this.checkOnClickNode) {
        this.toggleCheck(!data.checked, data)
      }
    },
    toggleExpand(item) {
      let status = !item.expand
      const currentData = this.treeMap.get(item[this.nodeKey]);
      currentData.expand = status;
      this.treeMap.set(item[this.nodeKey], currentData);
      this.$emit('on-toggle-expand', status, item)
    },
    toggleCheck(state, data) {
      const currentData = this.treeMap.get(data[this.nodeKey]);
      currentData.checked = state;
      let loopUpSetCheck = (key) => {
        /*向上查询并更改checked值，仅限halfWeek模式*/
        const parentData = this.treeMap.has(key) ? this.treeMap.get(key) : null;
        if (parentData) {
          parentData.checked = state;
          loopUpSetCheck(parentData.parentId);
        } else {
          loopUpSetCheck = null;
        }
      };

      switch (this.checkedType) {
        case 'strictly':/*严格遵循父子互相关联，子全选，父才选中,否则半选*/
          if (state) currentData.indeterminate = false;
          this.loopStrictly(currentData, state);
          break;
        case 'halfWeak':/*子选中其中一个父比选中*/
          if (state) {
            loopUpSetCheck(currentData.parentId);
          } else {
            if (currentData.children.length > 0) this.loopDown(currentData.children, state)
          }
          break;
        case 'weak':/*父子不互相关联*/
          break;
      }
      const checkKeys = [];
      const checkDataList = [];
      this.treeMap.forEach((key, value) => {
        if (key.checked) {
          checkKeys.push(value)
          checkDataList.push(key)
        }
      })
      this.$emit('on-checkbox-change', state, data, checkKeys, checkDataList)
    },
    /*向下查询并更改checked值*/
    loopDown(targetAry = [], state) {
      targetAry.forEach(item => {
        item.checked = state;
        if (item.children.length > 0) {
          this.loopDown(item.children)
        }
      })
    },
    loopStrictly(data, state) {
      /*向上查询并更改checked值，仅限strictly模式*/
      let loopUp = (key) => {
        const parentData = this.treeMap.has(key) ? this.treeMap.get(key) : null;
        if (parentData) {
          const checkDataLen = parentData.children.filter(item => item.checked).length
          parentData.indeterminate = checkDataLen !== 0 && checkDataLen !== parentData.children.length;
          parentData.checked = parentData.indeterminate ? false : state;
          loopUp(parentData.parentId);
        }
      };
      this.loopDown(data.children, state);
      loopUp(data.parentId);
    },
    forceUpdate() {
      this.initExpandKeys();
      this.getTreeData();
    },
  },
}
</script>

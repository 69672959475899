<template>
  <ys-container :menuList="menuList">
    <top-header slot="header"></top-header>
    <div slot="logo" class="logo">
      <img class="logo-img" src="../assets/images/logo.png" alt="logo">
      <h1 class="logo-title">{{ config.shortTitle }}</h1>
    </div>
    <div style="position: relative;">
      <routerContainer/>
    </div>
  </ys-container>
</template>

<script>
import routerContainer from './routerContainer.vue'
import topHeader from '_c/header/header.vue'
import {mapGetters} from 'vuex'

export default {
  name: 'Main',
  components: {
    routerContainer,
    topHeader,
  },
  computed: {
    ...mapGetters(['config', 'menuList'])
  }

}
</script>

<style lang="less" scoped>
.logo {
  display: flex;
  align-items: center;
  padding: 10px 23px;

  &-img {
    width: auto;
    height: 35px;
  }

  &-title {
    padding-left: 15px;
    font-size: 18px;
    color: #515a6e;
    height: 35px;
    flex: 1;
    line-height: 35px;
    overflow: hidden;
  }

  &-dark {
    &-title {
      color: rgba(255, 255, 255, .7);
    }
  }
}
</style>

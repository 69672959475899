<template>
  <div>
    <ys-map ref="ysMapRefs" :lat="lat" :lng="lng"  :address="address" @on-ok="getData"></ys-map>
    <div class="modalEditBtnArea" slot="bottomBtn" ref="bottomBtn">
      <Button type="primary" @click="confirm">确定</Button>
      <Button @click="close">取消</Button>
    </div>
  </div>
</template>

<script>

export default {
  name: "setLatLng",
  props: {
    lat: {type: [Number, String], default: null},
    lng: {type: [Number, String], default: null},
    address: {type: [String], default: null},
  },
  data() {
    return {
      modal: null,
    }
  },
  mounted() {
    this.modal = this.$parent.$parent;
  },
  methods: {
    close() {
      this.modal.modShow = false;
    },
    confirm() {
      this.$refs.ysMapRefs._save()
    },
    getData(data) {
      this.$emit('on-ok', data);
      this.close();
    },
  }
}
</script>

<style scoped>

</style>
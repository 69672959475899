<template>
  <div>
    <div :class="classes" style="position: relative;">
      <div class="search" v-if="mode!=='onlyView'">
        <AutoComplete
          transfer
          v-model="keyword"
          clearable
          ref="autoComplete"
          :icon="loading === 0 ? 'ios-search' : (loading === 1 ? 'ios-loading' : '')"
          placeholder="精准关键词检索"
          @on-focus="openSelectPop"
          @on-search="searchSuggest"
          @on-select="selectSuggest"
          style="width:300px">
          <Option v-for="option in suggestionList" :value="option.hotPointID" :key="option.id" style="z-index: 1000;">
            <p>{{ option.title }}</p>
            <p style="color: #959595;font-size: 12px">{{ option.address }}</p>
          </Option>
          <div v-if="suggestionList.length === 0" style="margin: 5px 0;padding: 5px 0;text-align: center;color: #c5c8ce;">{{loading === 1 ? '检索中...' : '无匹配数据'}}</div>
        </AutoComplete>
      </div>
      <div class="latlng" v-if="result.location">当前坐标：{{ locationStr }}</div>
      <img class="location" src="../../assets/images/location.png" alt="" v-if="mode!=='onlyView'">
      <div id="container"></div>
    </div>
  </div>
</template>

<script>
import {transformGCJ2WGS} from "../../lib/transformGCJ2WGS.js";
import {uuid} from '../../lib/index.js'
let map =  null;
let timer = null;
const prefixCls = 'ys-map';
let geocoder=null;
export default {
  name: "ys-map",
  props: {
    mode: {type: String, default: 'open'},//模式 open：无限制，onlyView：仅查看，无法放大缩小等其他操作
    lat: {type: [Number, String], default: 27.993864},
    lng: {type: [Number, String], default: 120.699269},
    address: {type: String, default: '温州市人民政府办公室'},
  },
  computed: {
    classes() {
      return prefixCls
    },
    suggest() {
      return async () => {
        return await new Promise((resolve, reject) => {
          if(!this.keyword) {
            return reject('no keyword')
          }
          let list = [];
          return /* eslint-disable */ new T.LocalSearch(map, {
            pageCapacity: 10,	// 每页显示的数量
            onSearchComplete: (result) => {
              list = (result?.pois || []).map(item => {
                item.title = item.name;
                item.id = uuid();
                return item;
              })
              resolve(list)
            }	//接收数据的回调函数
          }).search(this.keyword, 1)
        })
      }
    },
    locationStr() {
      return this.result.location  ? (this.result.location.lat + ',' + this.result.location.lng) : '27.993864,120.699269'
    }
  },
  watch: {
    "keyword"() {
      this.result.address = this.keyword;
    }
  },
  data() {
    return {
      keyword: '',
      loading: false,
      suggestionList: [],
      result: {
        address: '温州市人民政府办公室',
        location: {
          lat: 27.9937307619152,
          lng: 120.69941136528779,
        },
      },
      locationToAddress_fn: () => {}, // 防抖
    }
  },
  async mounted() {
    geocoder = /* eslint-disable */ new T.Geocoder(); // 新建一个正逆地址解析类
    if(!map) await this.init();
  },
  methods: {
    // 防抖函数，规定时间内重复调用仅执行最后一次
    debounce(func, wait) {
      let timeout;
      return function () {
        const context = this;
        const args = arguments;
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          try {
            func.apply(context, args);
          } catch (error) {
            console.log('error', error);
          }
        }, wait);
      };
    },
    async init() {
      await this.$nextTick(() => {
        // 转换坐标
        const data = transformGCJ2WGS(Number(this.lat) || 27.9937307619152, Number(this.lng) || 120.69941136528779);
        this.result.location.lat = Number(data.lat);
        this.result.location.lng = Number(data.lon);
        const center = /* eslint-disable */ new T.LngLat(this.result.location.lng, this.result.location.lat);
        map = /* eslint-disable */ new T.Map('container')
        map.centerAndZoom(center, 16);

        if (this.mode === 'onlyView') {
          // 禁止拖拽
          map.disableDrag();
          // 禁用滚轮放大缩小。
          map.disableScrollWheelZoom();
          // 禁用双击放大
          map.disableDoubleClickZoom();
          // 禁用键盘操作
          map.disableKeyboard();
          let infoWindow = /* eslint-disable */ new T.InfoWindow("<font color='#000000'>" + "位置：" + (this.keyword || '暂无') + "</font>");
          map.openInfoWindow(infoWindow, center); // 开启信息窗口
        }
        // 停止拖拽地图时触发
        map.on("dragend", () => this.getMapCenter());
        // 地图更改缩放级别结束时触发触发此事件。
        map.on("zoomend", () => this.getMapCenter());
        // 有传地址进来/默认地址，自动检索，弹出检索框
        this.keyword = this.address || '温州市人民政府办公室';
        // 防抖
        this.locationToAddress_fn = this.debounce(this.locationToAddress, 500);
      })
    },
    // 键入值返回对应建议内容
    async searchSuggest() {
      this.suggestionList = [];
      if (!this.keyword) return;
      this.loading = 1;
      await this.suggest().then(res => {
        this.suggestionList = res;
      }).catch(err => {
        console.log('err', err);
      })
      this.loading = 0;
      this.openSelectPop();
    },
    // 手动打开下拉框
    openSelectPop() {
      setTimeout(() => {
        try {
          this.$refs.autoComplete.$refs.select.visible = true;
        } catch (error) {
          console.log('error', error);
        }
      }, 500);
    },
    // 选择建议内容回调
    async selectSuggest(hotPointID) {
      let data = this.suggestionList.find(item => item.hotPointID === hotPointID);
      if (data) {
        const lng = data.lonlat.split(',')[0];
        const lat = data.lonlat.split(',')[1];
        map.panTo(/* eslint-disable */ new T.LngLat(lng, lat), 16);
        this.$nextTick(() => {
          this.result.address = this.keyword = data.address;
          this.locationToAddress_fn({lng, lat});
        })
      }
    },
    getMapCenter() {
      if (this.mode === 'onlyView') return
      const location = map.getCenter();
      this.result.address = this.keyword = '';
      this.locationToAddress_fn(location);
    },
    locationToAddress(_location = null) {
      try {
        // 同一经纬点，可能存在多个地址，有可能取出来的地址与所选并不是同一个，故而address直接取keyWord值，为空的时候再在结果中取
        const data = transformGCJ2WGS(Number(this.lat) || 27.9937307619152, Number(this.lng) || 120.69941136528779);
        const lat = (_location && _location.lat) ? _location.lat : Number(data.lat);
        const lng = (_location && _location.lng) ? _location.lng :  Number(data.lon);
        geocoder.getLocation(/* eslint-disable */ new T.LngLat(lng, lat), (res) => {
          let {location, addressComponent, formatted_address} = res;
          // keyWord为空则从返回结果中取
          if(this.keyword === '') {
            this.keyword = this.result.address = addressComponent.address || formatted_address;
          }
          // code以及经纬度直接从返回结果中取
          this.result.code = {countyCode: addressComponent.county_code};
          this.result.addressComponent = addressComponent;
          // 再将经纬度坐标转回去
          const locationData = transformWGS2GCJ(Number(location.lat), Number(location.lon))
          locationData.lng = locationData.lon;
          this.result.location = locationData;
          // this.$emit('on-ok', this.result);
        });
      } catch (error) {
        this.$Notice.error({
          title: "操作失败！",
          desc: '接口调用过于频繁，请稍后再试！'
        });
      }
    },
    _save() {
      if(!this.result.address) {
        this.$Notice.error({
          title: "保存失败！",
          desc: '地址不能为空！'
        });
        this.openSelectPop();
        return false;
      }
      let num = 0;
      timer = window.setInterval(() => {
        num += 1;
        const flag = this.result.address && this.result.location.lat && this.result.location.lng && this.result.code;
        if(flag) {
          clearInterval(timer);
          this.$emit('on-ok', this.result);
        } else if(num === 1) { // 首次获取不到数据，则重新获取
          if(!flag) this.locationToAddress_fn({
            lat: this.result.location.lat,
            lng: this.result.location.lng
          });
        }
      }, 200);
    }
  },
  destroyed() {
    try {
      map = null;
    } catch (error) {
      console.log(error);
    }
  }
}
</script>

<style scoped lang="less">
.ys-map {
  position: relative;

  #container {
    height: 70vh;
  }

  .location {
    position: absolute;
    left: 50%;
    top: 50%;
    margin-top: -25px;
    margin-left: -17px;
    z-index: 1000;
    width: 34px;
  }

  .latlng {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 1000;
    font-size: 12px;
    background-color: rgba(0, 0, 0, 0.4);
    padding: 5px 10px;
    color: #FFFFFF;
  }

  .search {
    position: absolute;
    z-index: 10000;
    left: 10px;
    top: 10px;
  }
}
</style>

<template>
  <ys-detail :icon="detailIcon" :loading="loading" :name="detail.title">
    <Tag size="medium" color="blue" slot="status">{{ detail.type|getBaseDataName('文章所属类型') }}</Tag>
    <template slot="brief">
      <ys-cell title="创建人" :label="detail.createdUser"/>
      <ys-cell title="创建时间" :label="detail.createdAt"/>
    </template>
    <template slot="main-left">
      <Tabs v-model="tab" name="detail" type="card" :key="detail.id">
        <Tab-pane label="文章" name="1" icon="md-document">
          <div :style="{'height':height-193+'px','padding': '20px', 'overflow-y':'auto'}" class="overflowY" v-if="tab === '1'">
            <div v-html="detail.content"></div>
          </div>
        </Tab-pane>
      </Tabs>
    </template>
    <template slot="main-right">
      <h1 class="h1Title">其他信息</h1>
      <ys-empty title="暂未开放！"></ys-empty>
    </template>
  </ys-detail>
</template>

<script>
import detail from '@/mixins/detail.js'

export default {
  name: "",
  mixins: [detail],
  components: {},
  data() {
    return {}
  },
  mounted() {
    this.getDetail();
  },
  methods: {
    getDetail() {
      this.loading = true;
      this.detail={};
      this.$get('/article/Info', {id: this.mid}).then(res => {
        this.loading = false;
        this.detail = res.data;
        if(this.detail.isRedirect === 1) {
          this.$Modal.confirm({
            title: '提示',
            content: '当前文章可跳转至其他网址,是否跳转?',
            onOk: () => {
              window.open(this.detail.redirectUrl)
            },
          });
        }
      })
    }
  }
}
</script>

<style scoped>
#container {
  width: 100%;
  height: 450px;
}
</style>
<template>
  <div class="review">
    <iframe v-if="['PDF'].indexOf(file.suffix)>=0" :src="file.url+'#toolbar=0'" frameborder="0" style="width: 100%;overflow-y: auto;height:calc(100vh - 190px)"></iframe>
    <iframe v-else-if="['DOC','DOCX','XLS','XLSX','PPT','PPTX'].indexOf(file.suffix)>=0" :src="'https://view.officeapps.live.com/op/view.aspx?src='+encodeURIComponent(file.url)+'#toolbar=0'" frameborder="0" style="width: 100%;overflow-y: auto;height:calc(100vh - 190px)"></iframe>
    <div v-else-if="['JPG', 'GIF', 'PNG', 'JPEG'].indexOf(file.suffix)>=0" style="width: 100%;overflow-y: auto;height:calc(100vh - 190px)">
      <img alt="" :src="file.url" style="width: 100%"/>
    </div>
  </div>
</template>

<script>
export default {
  name: "file-view",
  props:{
    file:{type:Object,default:()=>{return {}}}
  }
}
</script>

<style scoped>

</style>
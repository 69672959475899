<template>
  <div>
    <Dropdown @on-clickoutside="outside" @on-click="dropClick" transfer :visible="visible" @on-visible-change="visibleChange" placement="bottom-start" trigger="custom" style="width: 100%">
      <Input @on-clear="clear" clearable @on-change="orgNameChange" ref="orgName" @on-focus="visible=true" v-model="keyword" :disabled="disabled" placeholder="机构名称">
        <Button slot="append" @click="businessInfoFlag=true" icon="md-information-circle" :disabled="!uuid"></Button>
      </Input>
      <DropdownMenu slot="list" transfer :style="{width:dropDownWidth+'px'}" id="DropdownList">
        <Divider class="divider" size="small" orientation="center">本地机构库查询结果({{ orgListList.length }}条)</Divider>
        <DropdownItem class="ready" name="none" v-if="!keyword">请输入机构名称、社会信用代码查询</DropdownItem>
        <template v-else>
          <DropdownItem class="ready" name="none" v-if="loading">
            <Icon type="ios-loading spin-icon-load"></Icon>
            加载中......
          </DropdownItem>
          <template v-else>
            <DropdownItem class="ready" v-if="orgListList.length===0">暂无数据！</DropdownItem>
            <DropdownItem v-for="item in orgListList" :name="item.key" :key="item.key">
              <div class="orgList">
                <div class="logo">
                  <img :src="item.imageUrl" class="pic" v-if="item.imageUrl" alt="logo"/>
                  <ys-image :width="40" :height="40" :src="item.imageUrl" v-else></ys-image>
                </div>
                <div class="info">
                  <div class="name">{{ item.iname }}</div>
                  <div class="brief">社会统一代码：<span :style="{color: item.recommend?'#ff0900':''}">{{ item.societyCode }}</span> ，法人：{{ item.operName }}，状态：{{ item.regStatus || item.status }}</div>
                </div>
                <Tag color="error" v-if="item.recommend">推荐</Tag>
              </div>
            </DropdownItem>
          </template>
        </template>
        <template v-if="source!==1">
          <Divider class="divider" size="small" orientation="center">全国工商信息库查询结果({{ businessList.length }}条)</Divider>
          <DropdownItem v-if="!businessFlag" class="ready" name="none">{{ keyword ? '点击显示查询结果' : '请输入机构名称、社会信用代码查询' }}</DropdownItem>
          <template v-else>
            <DropdownItem class="ready" name="none" v-if="businessLoading">
              <Icon type="ios-loading spin-icon-load"></Icon>
              加载中......
            </DropdownItem>
            <template v-else>
              <DropdownItem class="ready" v-if="businessList.length===0">暂无数据！</DropdownItem>
              <DropdownItem v-for="item in businessList" :name="item.key" :key="item.key">
                <div class="orgList">
                  <div class="logo">
                    <img :src="item.imageUrl" class="pic" v-if="item.imageUrl" alt="logo"/>
                    <ys-image :width="40" :height="40" :src="item.imageUrl||''" v-else></ys-image>
                  </div>
                  <div class="info">
                    <div class="name">{{ item.iname }}</div>
                    <div class="brief">社会统一代码：<span :style="{color: item.recommend?'#ff0900':''}">{{ item.societyCode }}</span> ，法人：{{ item.operName }}，状态：{{ item.regStatus || item.status }}</div>
                  </div>
                  <Tag color="error" v-if="item.recommend">推荐</Tag>
                </div>
              </DropdownItem>
            </template>
          </template>
        </template>
      </DropdownMenu>
    </Dropdown>
    <ys-modal v-model="businessInfoFlag" footerHide :width="1000" title="工商信息详情">
      <info :mid="uuid"></info>
    </ys-modal>
  </div>
</template>

<script>
import info from './info'
import {generateSocialCreditCode, uuid} from "../../lib/index";

export default {
  name: "index",
  props: {
    autoSelect: {type: [Boolean], default: false},
    autoSelectName: {type: String, default: ''},
    disabled: {type: [Boolean], default: false},
    oType: {type: [Number], default: 4},
    type: {type: [Number], default: 1},
    source: {type: [Number], default: 1},
    value: {type: [Number, String], default: ''},
    isCreateOrg: {type: Boolean, default: true}, // 企查查库点击选中时是否需要在后台创建一条机构， 默认需要
    societyCode: {type: [Number, String], default: null},
  },
  components: {info},
  data() {
    return {
      dropDownWidth: 0,
      businessLoading: false,
      businessInfoFlag: false,
      loading: false,
      visible: false,
      businessFlag: false,
      timer: null,
      orgListList: [],
      businessList: [],
      targerOrgKey: '',
      uuid: '',
      keyword: ''
    }
  },
  watch: {
    value: function (val) {
      this.keyword = val;
      this.orgNameChange();
    },
  },
  mounted() {
    this.keyword = this.value
    this.orgNameChange();
  },
  methods: {
    orgNameChange() {
      window.clearTimeout(this.timer);
      this.timer = window.setTimeout(() => {
        if (this.keyword !== '') {
          this.loading = true;
          this.businessFlag = false
          this.$get('/organization/Search', {keyword: this.keyword, source: this.source, noPending: true}).then(res => {
            this.orgListList = (res.data.list || []).map(item => {
              item.key = uuid()
              if (this.autoSelect && item.iname === this.keyword) {
                this.targerOrgKey = item.key
              }
              return item;
            });
            if (this.societyCode && this.$reg.unifiedSocialCreditReg.reg.test(this.societyCode)) {
              this.orgListList = this.orgListList.map(item => {
                item.recommend = item.societyCode === this.societyCode;
                if (item.recommend && this.autoSelect) {
                  this.targerOrgKey = item.key
                }
                return item;
              });
            }
            if (this.autoSelect) {
              if (!this.targerOrgKey) {
                this.dropClick('none')
              } else {
                this.dropClick(this.targerOrgKey)
              }
            }
            window.clearTimeout(this.timer);
            this.loading = false;
          })
        }
      }, 300)
    },
    async dropClick(name) {
      if (!name) return;
      if (name === 'none') {
        if (!this.keyword) return;
        this.businessFlag = true;
        this.businessLoading = true;
        await this.$get('/business/Search', {keyword: this.keyword, noPending: true}).then(res => {
          this.businessList = (res.data.list || []).map(item => {
            item.key = uuid()
            if (this.autoSelect && item.iname === this.keyword) {
              this.targerOrgKey = item.key
            }
            return item;
          });
          if (this.societyCode && this.$reg.unifiedSocialCreditReg.reg.test(this.societyCode)) {
            this.businessList = this.businessList.map(item => {
              item.recommend = item.societyCode === this.societyCode;
              if (item.recommend && this.autoSelect) {
                this.targerOrgKey = item.key
              }
              return item;
            });
          }
          if (this.autoSelect && this.targerOrgKey) {
            this.dropClick(this.targerOrgKey)
          }
          this.businessLoading = false;
        })
      } else {
        let orgInfo = this.orgListList.find(item => item.key === name);
        if (!orgInfo) orgInfo = this.businessList.find(item => item.key === name);
        // 当后台不存在机构且需要创建的时候再创建机构
        if (!orgInfo.id && this.isCreateOrg) {
          orgInfo.societyCode = orgInfo.societyCode || generateSocialCreditCode();
          orgInfo.oType = this.oType;
          orgInfo.type = this.type;
          await this.$post('/organization/AddOrEdit', orgInfo).then(res => {
            if (res.code !== 200) {
              return false;
            } else {
              orgInfo.id = res.data.id;
              this.keyword = orgInfo.iname;
              this.uuid = orgInfo.uuid;
              this.$emit('on-select', orgInfo);
              this.visible = false;
            }
          });
        } else {
          this.keyword = orgInfo.iname;
          this.uuid = orgInfo.uuid;
          this.$emit('on-select', orgInfo);
          this.visible = false;
        }
      }
    },
    clear() {
      this.businessFlag = false;
      this.keyword = '';
      this.uuid = '';
      this.$emit('on-clear')
    },
    visibleChange() {
      const width = this.$refs['orgName'].$el.getBoundingClientRect().width
      this.dropDownWidth = width < 650 ? 650 : width;
    },
    outside(e) {
      let element = e.target || e.srcElement;
      //循环判断至跟节点，防止点击的是div子元素
      while (element) {
        if (element.id && element.id === 'DropdownList') {
          return;
        }
        element = element.parentNode;
      }
      this.visible = false;
    },
  }
}
</script>

<style scoped lang="less">
.orgList {
  display: flex;
  align-items: center;

  .logo {
    .pic {
      max-width: 40px;
      max-height: 40px;
      margin: 5px;
    }
  }

  .info {
    line-height: 20px;
    flex: 1;

    .brief {
      color: #999999;
      font-size: 12px;
    }
  }
}

.divider {
  color: #cccccc !important;
}

.ready {
  text-align: center;
}
</style>
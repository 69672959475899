<template>
  <div :class="classes" style="width: 100%;display: flex;">
    <Input v-model="modelAddress" :placeholder="placeholder" style="width: 90%;" @on-change="modelAddressChange">
      <template #prepend>
        <Tooltip max-width="400" :content="modelLat && modelLng ? ('坐标为：' + modelLat + ',' + modelLng) : '暂无坐标信息！'">
          <Icon type="ios-locate"/>
        </Tooltip>
      </template>
    </Input>
    <Button type="primary" icon="ios-pin-outline" @click="mapFlag=true" style="width: 10%;color: white"></Button>
    <ys-modal v-model="mapFlag" :width="1000" title="地址/坐标">
      <set-lat-lng ref="setLatLng" :lat="modelLat" :lng="modelLng" :address="modelAddress" @on-ok="getLocation" v-if="mapFlag"></set-lat-lng>
    </ys-modal>
  </div>
</template>

<script>
import {transformWGS2GCJ} from "../../lib/transformWGS2GCJ.js";
const prefixCls = 'ys-input-map';
import SetLatLng from "./setLatLng";

export default {
  name: "ys-input-map",
  components: {
    SetLatLng
  },
  props: {
    lat: {type: [Number, String], default: null},
    lng: {type: [Number, String], default: null},
    address: {type: String, default: null},
    placeholder: {type: String, default: '点击右侧图标选择地址'},
  },
  computed: {
    classes() {
      return prefixCls
    },
  },
  data() {
    return {
      mapFlag: false,
      modelLat: null,
      modelLng: null,
      modelAddress: '',
      result: {}
    }
  },
  mounted() {
    this.syncData(); 
  },
  watch: {
    lat: 'syncData',  
    lng: 'syncData',  
    address: 'syncData'  
  },
  methods: {
    syncData() {
      this.$nextTick(() => {
        if (this.lat && this.lng) {
          this.modelLat = Number(this.lat);
          this.modelLng = Number(this.lng);
        } else {
          this.modelLat = null;
          this.modelLng = null;
        }
        this.modelAddress = this.address || '';
      })
    },
    modelAddressChange() {
      this.result = this.modelAddress;
      this.$emit('on-ok', {address: this.modelAddress});
    },
    getLocation(data) {
      const latLng = transformWGS2GCJ(data.location.lat, data.location.lng);
      this.modelLat = latLng.lat
      this.modelLng = latLng.lon
      this.modelAddress = data.address
      const {addressComponent} = data
      if (addressComponent) {
        data.code.cityCode = addressComponent?.city_code ? addressComponent?.city_code.substring(3, 9) : '';
        data.code.countyCode = addressComponent?.county_code ? addressComponent?.county_code.substring(3, 9) : '';
        data.code.provinceCode = addressComponent?.province_code ? addressComponent?.province_code.substring(3, 9) : '';
      }
      this.result = data;
      this.$emit('on-ok', this.result);
    }
  },
}
</script>

<style scoped lang="less">
.ys-map {
  position: relative;

  #container {
    height: 70vh;
  }

  .location {
    position: absolute;
    left: 50%;
    top: 50%;
    margin-top: -25px;
    margin-left: -17px;
    z-index: 1;
    width: 34px;
  }

  .latlng {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 1;
    font-size: 12px;
    background-color: rgba(0, 0, 0, 0.4);
    padding: 5px 10px;
    color: #FFFFFF;
  }

  .search {
    position: absolute;
    z-index: 10000;
    left: 10px;
    top: 10px;
  }

}

</style>

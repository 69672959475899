<template>
  <div>
    <Form ref="form" class="editForm" :model="form" :rules="ruleValidate" :label-width="100">
      <Alert type="error" show-icon v-if="weekPassWord">您的密码过于简单，出于安全考虑请修改密码，否则无法登录系统！</Alert>
      <FormItem label="原密码" prop="Pass" v-if="!mid">
        <Input type="password" v-model="form.Pass" placeholder="请输入原密码"></Input>
      </FormItem>
      <FormItem label="新密码" prop="New_pass">
        <Input type="password" v-model="form.New_pass" placeholder="请输入新密码"></Input>
      </FormItem>
      <FormItem label="重复新密码" prop="Re_pass">
        <Input type="password" v-model="form.Re_pass" placeholder="请输入新密码"></Input>
      </FormItem>
    </Form>
    <div class="modalEditBtnArea">
      <Button type="primary" @click="ok('user/EditPwd')">确定</Button>
      <Button @click="close" v-if="!weekPassWord">取消</Button>
    </div>
  </div>
</template>
<script>
import {mapGetters, mapMutations} from 'vuex'
import editMixins from '@/mixins/edit'; //引入混入组件edit

export default {
  name: '',
  mixins: [editMixins],
  props: {
    weekPassWord: {type: Boolean, default: false}
  },
  computed: {
    ...mapGetters(['userInfo']),
  },
  data() {
    return {
      ruleValidate: {
        Pass: [
          {required: true, message: '原密码不能为空！', trigger: 'blur'}
        ],
        New_pass: [
          {
            required: true, trigger: 'blur', validator: (rule, value, callback) => {
              if (value === '') {
                callback(new Error('新密码不能为空!'));
              } else {
                const reg = new RegExp(/^(?![^a-zA-Z]+$)(?!\D+$)/);
                if (reg.test(this.form.New_pass) && this.form.New_pass.length > 6) {
                  callback()
                } else {
                  callback(new Error('密码长度必须大于6位，且包含数字和字母!'));
                }
              }
            }
          }
        ],
        Re_pass: [
          {
            required: true, trigger: 'blur', validator: (rule, value, callback) => {
              if (value === '') {
                callback(new Error('请再次输入密码!'));
              } else if (value !== this.form.New_pass) {
                callback(new Error('两次密码输入不一致，请确认!'));
              } else {
                callback();
              }
            }
          },
        ]
      },
      form: {
        Id: '',
        New_pass: '',
        Pass: '',
        Re_pass: '',
      },
    }
  },
  mounted() {
    this.form.Id = this.mid || this.userInfo.id;
  },
  methods: {
    ...mapMutations(['setToken']),
    ok(url, method) {
      this.modal.spinShow = true;
      this.$refs['form'].validate(valid => {
        if (valid) {
          this[method || '$post'](url, this.form).then((res) => {
            this.modal.spinShow = false;
            if (res.code === 200) {
              this.$Notice.success({
                title: "提示！",
                desc: '密码改成功，5秒后跳转到登录页面！'
              });
              this.close();
              this.$emit('on-ok');
              window.setTimeout(() => {
                this.$router.push({name: 'login'})
              }, 5000);
              this.setToken();
              this.setUserInfo();
              sessionStorage.clear();


            }
          }).catch(() => {
            this.modal.spinShow = false;
          })
        } else {
          this.$Notice.error({
            title: "操作失败！",
            desc: '请检查必填项！'
          });
          this.modal.spinShow = false;
        }
      });
    }
  }
}
</script>

<template>
  <div>
    <Alert type="warning">导出前可以拖动排序【导出列项】！</Alert>
    <Form ref="form" class="editForm" :model="form" :rules="ruleValidate" :label-width="70">
      <FormItem label="导出列" prop="checkKey">
        <CheckboxGroup v-model="form.checkKey">
          <draggable v-model="keysList" handle=".mover" chosenClass="moverClass" force-fallback="true" animation="100">
            <Checkbox v-for="(item,index) in keysList" border class="checkbox-border-item mover" :label="item.key" :key="index">
              {{ item.keyName || item.title }}
              <Tooltip content="该列项为系统固定配置项，导出后可能内容会错误或者不显示，请注意！" :max-width="300" v-if="item.type==='system'">
                <Icon type="ios-alert" color="#ff6600"/>
              </Tooltip>
            </Checkbox>
          </draggable>
        </CheckboxGroup>
      </FormItem>
      <FormItem label="文件名" prop="fileName">
        <Input v-model="form.fileName" placeholder="文件名"></Input>
      </FormItem>
      <FormItem label="文档标题" prop="title">
        <Input v-model="form.title" placeholder="文档标题，可不填，空时导出的文档不显示标题！"></Input>
      </FormItem>
    </Form>
    <div class="modalEditBtnArea">
      <Button type="primary" @click="onOk">导出</Button>
      <Button @click="close">取消</Button>
    </div>
  </div>
</template>

<script>
import axios from '../../request'
import draggable from "vuedraggable";

import {downByFileStream} from '../../lib/index'

export default {
  name: "export",
  components: {draggable},
  props: {
    exportAction: {type: String, default: '/excel/Export'},
    exportTitle: {type: String, default: null},
    exportFileName: {type: String, default: null},
    action: {type: String, default: null},
    method: {type: String, default: null},
    params: {type: [Object, Array], default: null},
    keyID: {type: String, default: null},
    columns: {type: Array, default: () => []},
    autoExpert: {type: Boolean, default: true}, // 是否支持自动导出列表为excel
  },
  data() {
    return {
      ruleValidate: {
        fileName: [
          {required: true, message: '请填写导出的文件名称', trigger: 'blur'}
        ],
      },
      form: {fileName: '', title: '', checkKey: [], checkListKey: [],},
      keysList: [],
    }
  },
  mounted() {
    this.form.title = this.exportTitle;
    this.form.fileName = this.exportFileName || (this.$route.meta ? this.$route.meta.title : '');
    this.form.checkKey = this.columns.filter(item => !item.type).map(item => item.key)
    this.getPageKeys();
  },
  methods: {
    getPageKeys() {
      this.$get('/pageListKeys/List', {keyId: this.keyID, limit: -1}).then(res => {
        if (res.code === 200) {
          if (res.data.list) {
            let columns = this.columns.filter(item => !item.type)
            this.keysList = res.data.list.map(item => {
              let filterColumn = columns.filter(subItem => subItem.key === item.key);
              return filterColumn.length > 0 ? {
                key: filterColumn[0].key,
                keyName: filterColumn[0].title,
                type: filterColumn[0].renderConfig ? filterColumn[0].renderConfig.type : '',
                typeParams: filterColumn[0].renderConfig || {},
              } : item;
            });
          } else {
            this.$post('/page-list/InsertKeys', {
              columns: this.columns.map((item, index) => {
                item.order = index + 1;
                return item;
              }), keyId: this.keyID
            }).then(res => {
              if (res.code === 200) this.getPageKeys();
            })
          }
        }
      })
    },
    async exportFile() {
      const {params} = this;
      let param
      if (Array.isArray(params)) {
        param = [];
        params.forEach(parentItem => {
          let obj = '';
          Object.keys(parentItem).forEach((item, index) => {
            if (item === 'limit') parentItem[item] = -1;
            obj = obj + item + '=' + (parentItem[item] == undefined ? '' : parentItem[item]) + (index !== Object.keys(parentItem).length - 1 ? '&' : '')
          })
          param.push(obj);
        })
      } else {
        param = '';
        Object.keys(params).forEach((item, index) => {
          if (item === 'limit') params[item] = -1;
          param = param + item + '=' + (params[item] == undefined ? '' : params[item]) + (index !== Object.keys(params).length - 1 ? '&' : '')
        })
      }
      this.$parent.$parent.spinShow = true;
      if(!this.autoExpert) { // 非自动导出，纯前端手动导出
        this.$emit('on-ok', this.form, this.keysList)
        return;
      } else {
        await downByFileStream({
          url: this.exportAction,
          method: 'post',
          fileName: this.form.fileName + '.xlsx',
          params: {
            url: (window.location.host.split('localhost').length === 1 ? window.location.protocol + '//' + window.location.host : 'https://adm-test.us-smart.cn') + axios.defaults.baseURL + '/' + this.action,
            fileName: this.form.fileName,
            title: this.form.title,
            [this.exportAction === '/excel/Export' ? 'param' : 'paramList']: param,
            columns: this.keysList.filter(item => this.form.checkKey.indexOf(item.key) >= 0).map(({...item}) => {
              item.title = item.keyName;
              item.renderConfig = {type: item.type}
              switch (item.type) {
                case 'baseData':
                  item.renderConfig.parentName = item.typeParams.parentName;
                  break;
                case 'formatDate':
                  item.renderConfig.fmt = item.typeParams.fmt;
                  break;
              }
              item.type = null;
              return item
            })
          },
        }).then(() => {
          this.$emit('on-close')
        }).catch((err) => {
          this.$Notice.error({
            title: "操作失败！",
            desc: err
          });
          this.$parent.$parent.spinShow = false;
        })
      }
    },
    onOk() {
      this.$parent.$parent.spinShow = true;
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.exportFile()
        }
      })
    },
    close() {
      this.$emit('on-close')
    },
  }
}
</script>

<style scoped>
.checkbox-border-item {
  margin-bottom: 10px;
  padding: 0 10px;
}

</style>
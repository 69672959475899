<template>
  <div ref="pageList" :class="classCls" :style="pageListStyle">
    <div :class="searchCls" ref="searchBox" v-if="pageListData.header">
      <div :class="searchBoxCls">
        <Form ref="searchForm" :class="searchFormCls" label-position="right" :model="pageListData.params" @keydown.native.enter.prevent="() => {getList(null,true);}" inline :labelWidth="showMoreFlag?100:null">
          <slot name="search" v-if="!pageListData.filter||pageListData.filter.length===0"></slot>
          <div ref="generateForm" v-else style="display: inline">
            <FormItem :label="item.originType!=='checkBox'||showMoreFlag?item.label:''" :show="!!item.show" :prop="item.key" v-for="(item,index) in pageListData.filter" :key="item.id">
              <Select v-model="pageListData.params[item.key]" :class="{selectMultiple:item.limit===0}" :multiple="item.limit===0?true:false" :clearable="!!item.clearable" transfer :placeholder="item.placeholder" :style="{width: item.width+'px'}" v-if="item.originType==='baseData'">
                <Option v-for="(item,index) in baseData[item.originValue]" :value="item.value" :key="index">{{ item.name }}</Option>
              </Select>
              <ys-region :ref="'region'+item.key" @on-change="region($event,index)" changeOnSelect :clearable="!!item.clearable" v-model="pageListData.params[item.key]" :style="{width: item.width+'px'}" v-else-if="item.originType==='region'"></ys-region>
              <Input :placeholder="item.placeholder" :clearable="!!item.clearable" :style="{width: item.width+'px'}" v-model="pageListData.params[item.key]" v-else-if="item.originType==='input'"></Input>
              <ys-date-picker v-model="pageListData.params[item.key]" :clearable="!!item.clearable" :style="{width: item.width+'px'}" :placeholder="item.placeholder" v-else-if="item.originType==='dateTimer'"></ys-date-picker>
              <Checkbox v-model="pageListData.params[item.key]" border v-else-if="item.originType==='checkBox'">{{ item.label }}</Checkbox>
            </FormItem>
          </div>
          <div :class="searchBtnCls" ref="searchBtn" :style="{'display':showMoreFlag?'block':'inline-flex'}" style="margin-top: 1px;">
            <ButtonGroup>
              <Button type="primary" v-show="pageListData.btnArray.indexOf('fresh')>=0" @click="searchFun?searchFun():getList(null,true)" :loading="searchLoading">搜索</Button>
              <Button type="primary" v-show="pageListData.btnArray.indexOf('reset')>=0" @click="reset">重置</Button>
            </ButtonGroup>
            <ButtonGroup>
              <Button @click="showAllSearch" v-show="searchMoreFlag">{{ showMoreFlag ? '收起筛选' : '展开筛选' }}</Button>
            </ButtonGroup>
          </div>
        </Form>
      </div>
      <div ref="setHeaderBtn" :class="`${searchCls[0]}-setHeader`" :style="{'display':showMoreFlag?'none':''}" v-show="!showMoreFlag">
        <slot name="btn"></slot>
        <ButtonGroup v-if="(pageListData.btnArray.indexOf('down')>=0||pageListData.btnArray.indexOf('set')>=0||pageListData.btnArray.indexOf('refresh')>=0) && keyID">
          <Button type="primary" v-show="pageListData.btnArray.indexOf('down')>=0" title="导出" icon="md-cloud-download" @click="exportFlag = true">导出</Button>
          <Button type="primary" v-show="pageListData.btnArray.indexOf('set')>=0" title="自定义列项" icon="ios-settings" @click="setColumnFlag = true"></Button>
          <Button type="primary" v-show="pageListData.btnArray.indexOf('refresh')>=0" title="刷新" icon="md-refresh" @click="reFresh"></Button>
        </ButtonGroup>
      </div>
    </div>
    <template v-if="tabList&&tabList.length>0">
      <div :class="searchTabCls">
        <ul>
          <li v-for="(item,index) in tabList" :key="index" :class="{active:tabIndex===index}" @click="tabChange(index)">
            {{ item.name }}{{ item.num || item.num === 0 ? '(' + item.num + ')' : '' }}
          </li>
        </ul>
      </div>
    </template>
    <div :class="tableBoxCls">
      <Table
          :key="keyIndex"
          ref="table"
          class="table"
          :size="tableSize||config.size"
          :border="config.border"
          :load-data="loadData"
          :summary-method="summaryMethod?summaryMethod:(allData.allTotal?handleSummary:null)"
          :stripe="config.stripe"
          :highlight-row="true"
          :max-height="maxHeight"
          :height="pageListHeight"
          :no-data-text="noDataText"
          :loading="searchLoading"
          :show-summary="showSummary"
          :context-menu="pageListData.showContextMenu"
          :show-context-menu="pageListData.showContextMenu"
          :row-class-name="rowClassName || rowClassFn"
          @on-row-dblclick="dblclick"
          @on-row-click="click"
          @on-contextmenu="handleContextMenu"
          @on-select-all-cancel="selectAllCancel"
          @on-select-cancel="selectCancel"
          @on-selection-change="checkItem"
          @on-column-width-resize="widthResize"
          :columns="columns"
          :row-key="keyLabel"
          :data="tableData||List">
        <template slot="contextMenu">
          <div class="contextMenu">
            <slot name="contextMenu" v-if="$slots.contextMenu"></slot>
            <template v-else>
              <template v-for="(item,index) in multiBtn">
                <template v-if="Array.isArray(item.children)">
                  <ys-cell-menu-group :key="index">
                    <ys-cell-menu
                        v-for="(subItem,subIndex) in item.children" :key="subIndex"
                        v-show="subItem.show===undefined?true:subItem.show"
                        :iconColor="subItem.iconColor"
                        :styles="subItem.styles"
                        :perIcon="subItem.icon"
                        :targetUrl="subItem.targetUrl"
                        :label="subItem.targetUrl?'新窗口':''"
                        @on-click="()=>{subItem.type==='detail'?detailFlag=true:subItem.click()}"
                        :disabled="detailFlag||subItem.disabled"
                        :loading="subItem.loading"
                        :title="subItem.title"></ys-cell-menu>
                  </ys-cell-menu-group>
                </template>
                <template v-else>
                  <ys-cell-menu
                      :key="index"
                      v-show="item.show===undefined?true:item.show"
                      :styles="item.styles"
                      :targetUrl="item.targetUrl"
                      :label="subItem.targetUrl?'新窗口':''"
                      :iconColor="item.iconColor"
                      :color="item.color"
                      :perIcon="item.icon"
                      @on-click="()=>{item.type==='detail'?detailFlag=true:item.click()}"
                      :disabled="item.disabled"
                      :title="item.title"></ys-cell-menu>
                </template>
              </template>
            </template>
          </div>
        </template>
      </Table>
      <tableTotal v-if="isTotal&&!loading" :headerColumns="columns" :data="allData"></tableTotal>
      <div :class="footerCls" v-if="pageListData.footer&&(pageListData.isPage||pageListData.isMultiple)" :style="pageBottomStyle">
        <div :class="multipleCls" v-if="pageListData.isMultiple">
          <div :class="`${multipleCls}-clear`" title="清空选择项" @click="selectAll(false)">
            <Icon type="ios-close-circle" :class="`${multipleCls}-clearIcon`+(tableSelectItem.length === 0?` ${multipleCls}-disabled`:'')" size="20"/>
          </div>
          <div :class="btnCls">
            <div :class="`${btnCls}-multiNum`"> 已选择 <em :class="`${btnCls}-multiNum-fontRed`">{{ tableSelectItem.length }}</em> 项</div>
            <slot name="multiBtn" v-if="$slots.multiBtn"></slot>
            <div :class="multiBtnCls" v-else>
              <template v-for="(item,index) in multiBtn">
                <template v-if="Array.isArray(item.children)">
                  <ButtonGroup :key="index">
                    <Button
                        v-for="(subItem,subIndex) in item.children"
                        type="primary"
                        v-show="subItem.show===undefined?true:subItem.show"
                        :icon="item.hiddenBtnIcon===false?subItem.icon:''"
                        :key="subIndex"
                        @click="()=>{subItem.type==='detail'? detailFlag=true:subItem.click()}"
                        :disabled="detailFlag||subItem.disabled"
                        :loading="subItem.loading">{{ subItem.title }}
                    </Button>
                  </ButtonGroup>
                </template>
                <template v-else>
                  <Button
                      type="primary"
                      :key="index"
                      v-show="item.show===undefined?true:item.show"
                      @click="()=>{item.type==='detail'? this.detailFlag=true:item.click()}"
                      :icon="item.hiddenBtnIcon?'':item.icon"
                      :disabled="item.disabled">{{ item.title }}
                  </Button>
                </template>
              </template>
            </div>
          </div>
          <Page v-if="pageListData.isPage" show-total :key="keyIndex" :total="pageListData.params.total" :page-size="pageListData.params.pageSize || config.pageSize"
                show-elevator :current="pageListData.params.page" show-sizer :page-size-opts="[10, 20, 30, 40, 50, 60, 70, 80, 90, 100]" @on-change="pageChange"
                @on-page-size-change="pageSizeChange" placement="top">
          </Page>
        </div>
      </div>
    </div>
    <ys-drawer v-model="setColumnFlag" :width="600">
      <Column
          :action="action"
          :keyID="keyID"
          :method="method"
          :params="params"
          :columns="columns"
          :btnArray="btnArray"
          @on-ok="reFresh"
          @on-close="setColumnFlag=false"></Column>
    </ys-drawer>
    <ys-modal v-model="exportFlag" :width="800" title="导出">
      <export
          :action="action"
          :method="method"
          @on-close="exportFlag=false"
          :keyID="keyID"
          :putOutPageSize="putOutPageSize"
          :params="params"
          @on-ok="handPutOutFile"
          :tableData="tableData"
          :columns="columns"
          :autoExpert="autoExpert"></export>
    </ys-modal>
    <ys-drawer :inner="detailInner" @on-visible-change="onVisibleChange" v-model="detailFlag" :loading="drawerLoading">
      <slot name="detail"></slot>
    </ys-drawer>
  </div>
</template>
<script>
import {clone, comparisonObject, fixed, formatDate, getBaseDataName, getRegionByCode, flatten, phoneDesensitise, policyNoDesensitise, IDDesensitise} from "../../lib/index";
import {clearPending} from '../../request/axios-function'
import bus from '../../bus/index'
import Column from "./column";
import ysRegion from "../ys-region";
import ysDatePicker from "../ys-date-picker";
import Vue from 'vue'
import {Tooltip} from 'view-design';
import Export from "./export";
import tableTotal from './total'

const prefixCls = 'ys-page-list';
const tooltipRender = (h, tooltip = false, content) => {
  return h('div', {
    attrs: {
      class: tooltip ? 'tooltip' : ''
    },
  }, [h('span', {domProps: {innerHTML: content}})])
}
export default {
  name: "ys-page-list",
  components: {
    tableTotal,
    Export,
    ysDatePicker,
    Column,
    ysRegion,
    'ys-cell-menu-group': () => import('../ys-cell-menu/group'),
    'ys-cell-menu': () => import('../ys-cell-menu/index'),
  },
  props: {
    'detailInner': {type: Boolean, default: false},/*详情的抽屉是否inner属性*/
    'showSummary': {type: Boolean, default: false},/*是否显示系统自带的合计*/
    'summaryMethod': {type: Function, default: null},/*是否显示系统自带的合计*/
    'frozenData': {type: Boolean, default: true},/*是否冻结数据*/
    'tableLoading': {type: Boolean, default: false},/*表格加载状态*/
    'lineSelect': {type: Boolean, default: true},/*是否点击一行选中数据*/
    'keyLabel': {type: String, default: 'id'},/*key关键字，用于识别渲染的数据唯一性*/
    'searchFun': {type: Function, default: null},/*搜索方法，不是太好的解决方案*/
    'isTotal': {type: Boolean, default: false},/*是否显示合计信息，非自带*/
    'multiColumn': {type: Boolean, default: true},/*是否显示操作一列*/
    'showContextMenu': {type: Boolean, default: true},/*是否显示右键菜单*/
    'maxHeight': {type: [String, Number], default: null},/*表格高度*/
    'tableHeight': {type: [String, Number], default: null},/*表格高度*/
    'tableWidth': {type: Number,},/*表格宽度*/
    'header': {type: Boolean, default: true},/*是否显示头部*/
    'isPage': {type: Boolean, default: true},/*是否显示翻页，footer=true可用*/
    'isMultiple': {type: Boolean, default: true},/*是否显示多选操作按钮*/
    'footer': {type: Boolean, default: true},/*是否显示底部信息*/
    'method': {type: String, default: '$get'},/*请求方法*/
    'noDataText': {type: String, default: '暂无数据！'},/*无数据时显示的文字信息*/
    'model': {type: String, default: 'normal'},//'normal和onlyView'，暂无用
    'type': {type: String, default: 'table'},//'table和card'，暂无用
    'tableSize': {type: String, default: 'default'},//'table和card'，暂无用
    'action': {type: String, default: null},/*请求地址*/
    'keyID': {type: String, default: null},/*uuid 此数据存在则会请求获取表头信息*/
    'rowClassName': {type: Function,},
    'loadData': {type: Function,},
    'btnArray': {type: Array, default: () => ['fresh', 'reset', 'down', 'set']},
    'params': {
      type: Object, default: () => {
        return {}
      }
    },/*请求的参数*/
    'headerColumns': {type: Array, default: () => []},/*默认表头信息*/
    'IDs': {type: Array, default: () => []},/*选中的数据this.keyLabel集合*/
    'multiBtn': {type: Array, default: () => []},/*多选操作按钮集合 需从computed计算*/
    'tableData': {type: Array, default: () => null},/*表格数据集，在无action时可用*/
    'tabList': {type: Array, default: () => null},/*tab筛选项*/
    'isEmptySelect': {type: Boolean, default: true},/*是否切页是清空已选中数据 */
  },
  computed: {
    pageListHeight() {
      let heightVal = this.tableHeight ? this.tableHeight : (this.config.scroll ? 'auto' : this.height - 206) - 10;
      return this.tabList ? (heightVal - 35) : heightVal;
    },
    searchLoading() {
      return this.tableLoading || this.loading;
    },
    searchCls() {
      return [
        `${prefixCls}-search`,
        this.showMoreFlag ? 'showActive' : ''
      ]
    },
    searchTabCls() {
      return [this.classCls + '-tab']
    },
    pageListStyle() {
      return {
        marginBottom: this.config.scroll ? '47px' : 0
      }
    },
    tableBoxCls() {
      return [`${this.classCls}-tableBox`]
    },
    footerCls() {
      return [`${this.tableBoxCls}-footer`]
    },
    multipleCls() {
      return [`${this.footerCls}-multiple`]
    },
    btnCls() {
      return [`${this.multipleCls}-btn`]
    },
    multiBtnCls() {
      return [`${this.btnCls}-multiBtn`]
    },
    searchBoxCls() {
      return [`${this.searchCls[0]}-searchBox`]
    },
    searchFormCls() {
      return [`${this.searchBoxCls}-searchForm`]
    },
    searchBtnCls() {
      return [`${this.searchFormCls}-searchBtn`]
    },
    classCls() {
      return [prefixCls]
    },
    columns() {
      return (this.pageListData.columns || []).filter(item => item.isShow !== false);
    },
    baseData() {
      return window.baseData;
    }
  },
  data() {
    return {
      tabIndex: 0,
      putOutPageSize: -1,
      requestReady: false,
      exportFlag: false,
      config: localStorage.getItem('set') ? JSON.parse(localStorage.getItem('set')) : {},
      keyIndex: 1,
      setColumnFlag: false,
      detailFlag: false,
      drawerLoading: false,
      loading: false,
      putOutFlag: false,
      modShow: false,
      searchMoreFlag: true,
      showMoreFlag: false,
      putOutLoading: true,
      autoExpert: true, // 是否支持自动导出列表为excel
      pageListData: {columns: null, params: {}, filter: null, btnArray: []},
      pageBottomStyle: {},
      tableSelectItem: [],
      height: null,
      List: [],
      perParams: {},//上一次查询的参数，用于对比本次参数变化而重置page=1
      initParams: null,//本分初始化参数，用于重置
      allData: {
        allTotal: {},
      },
      // SelectedIDs: [],
      fileType: {
        AVI: require('../../assets/images/AVI.png'),
        CDR: require('../../assets/images/CDR.png'),
        CSS: require('../../assets/images/CSS.png'),
        DOC: require('../../assets/images/DOC.png'),
        DOCX: require('../../assets/images/DOCX.png'),
        PDF: require('../../assets/images/PDF.png'),
        MOV: require('../../assets/images/MOV.png'),
        HTML: require('../../assets/images/HTML.png'),
        MP3: require('../../assets/images/MP3.png'),
        MP4: require('../../assets/images/MP4.png'),
        PHP: require('../../assets/images/PHP.png'),
        PPT: require('../../assets/images/PPT.png'),
        PPTX: require('../../assets/images/PPTX.png'),
        EPS: require('../../assets/images/EPS.png'),
        PSD: require('../../assets/images/PSD.png'),
        RAR: require('../../assets/images/RAR.png'),
        TTF: require('../../assets/images/TTF.png'),
        TXT: require('../../assets/images/TXT.png'),
        XLS: require('../../assets/images/XLS.png'),
        XLSX: require('../../assets/images/XLSX.png'),
        ZIP: require('../../assets/images/ZIP.png'),
        NONE: require('../../assets/images/unknown.png'),
      },
      imgTypeList: ['JPG', 'GIF', 'PNG', 'JPEG'],
    }
  },
  watch: {
    'config.pageSize': function () {
      this.keyIndex = new Date().getTime();
      this.setPageBottomStyle();
      this.pageListData.params.page = 1;
      this.pageListData.params.limit = this.config.pageSize;
      this.getList();
    },
    'tableSelectItem': function (val) {
      this.$emit("on-selection-change", val);
      if (val.length !== 1) this.detailFlag = false;
    },
  },
  created() {
    bus.$on('set', (data) => {
      this.config = data
    })
  },
  async mounted() {
    this.height = document.documentElement.clientHeight;
    if (!this.config.scroll) window.addEventListener('resize', this.onWindowResize, false);
    if (!this.keyID) await this.getColumns();
    await this.setPageBottomStyle();
  },
  methods: {
    tabChange(index) {
      this.tabIndex = index;
      this.$emit('on-tab-change', this.tabList[index], index)
    },
    reset() {
      this.tabIndex = 0;
      this.$refs.searchForm.resetFields();
      Object.keys(this.pageListData.params).forEach(item => {
        if (!this.initParams) return
        this.pageListData.params[item] = this.initParams[item];
      });
      this.$emit('reset', this.pageListData.params)
      this.getList(null, false, true)
    },
    handleSummary({columns}) {
      const sums = {}, {allTotal} = this.allData;
      columns.forEach((item) => {
        const key = item.key;
        sums[key] = {
          key,
          value: allTotal[key] !== undefined ? allTotal[key] : '-'
        }
      })
      return sums;
    },
    setPageBottomStyle() {
      window.setTimeout(() => {
        if (!this.$refs['pageList']) return;
        let width = this.$refs['pageList'].offsetWidth;
        this.pageBottomStyle = this.config.scroll ?
            {
              position: 'fixed',
              bottom: '0px',
              margin: '0 1px',
              boxShadow: '0 0 5px rgba(0,0,0,0.1)',
              width: width - 2 + 'px',
              zIndex: 10,
            } : {}
      }, 200)
    },
    onVisibleChange(status) {
      if (!status) clearPending();
      if (status && this.config.drawerCollapsed) {
        this.config.collapse = true;
        bus.$emit('set', this.config);
        localStorage.setItem('set', JSON.stringify(this.config));
      }
    },
    onWindowResize() {
      let timer = null;
      window.clearTimeout(timer);
      timer = window.setTimeout(() => {
        this.height = document.documentElement.clientHeight;
      }, 100)
    },
    setFunResult(item, keyList) {
      for (let key in item) {
        if (keyList[key] && item[key] !== '' && item[key] !== undefined) {
          switch (keyList[key].type) {
            case 'baseData':
              item[key] = getBaseDataName(keyList[key].parentName, item[key], false);
              break;
            case 'area':
              item[key] = getRegionByCode(item[key]);
              break;
            case 'formatDate':
              item[key] = formatDate(item[key], keyList[key].fmt || 'yyyy-MM-dd');
              break;
            default:
              item[key] = item[key] + "\t";
              break;
          }
        }
      }
      return item;
    },
    // 外界通过ref直接调用手动搭导出
    openHandPutOutFile() {
      this.autoExpert = false;
      this.exportFlag = true;
    },
    // 手动导出列表（也可支持自动全部导出）
    async handPutOutFile(form) {
      let params = clone(this.pageListData.params);
      params.limit = this.putOutPageSize;
      if (this.tableData) {
        // 手动导出的话，根据keyLabel将数据过滤出来
        const data = this.autoExpert ? this.tableData : this.tableData.filter(item => {
          return this.tableSelectItem.some(ele => ele[this.keyLabel] === item[this.keyLabel]);
        })
        const columns = this.columns.filter((item) => {
          return form.checkKey.indexOf(item.key) !== -1 && item.type !== 'selection' && item.isShow === false;
        });

        this.$refs.table.exportCsv({
          filename: form.fileName,
          quoted: true,
          columns: columns,
          data: data,
        });
      } else {
        await this[this.method](this.action, params).then((res) => {
          let dataBase;
          let renderHeader = {};
          this.columns.forEach((item) => {
            if (item.isShow && !item.type && item.renderConfig) renderHeader[item.key] = item.renderConfig; //得到有table表格内有方法render表头的key数组
          });
          dataBase = (res.data.list || []).map(({...item}) => {
            return this.setFunResult(item, renderHeader);
          });
          // 扁平化处理
          const flattenColumns = flatten(this.columns, 'children')
          // 手动导出的话，根据keyLabel将数据过滤出来
          const data = this.autoExpert ? dataBase : dataBase.filter(item => {
            return this.tableSelectItem.length > 0 ? this.tableSelectItem.some(ele => ele[this.keyLabel] === item[this.keyLabel]) : item;
          }).map(item => {
            Object.keys(item).forEach(key => {
              // 加上 ' 以防止excel打开时将开头 0 去掉
              if (flattenColumns.find(ele => ele.key === key)?.isEscape) {
                /* eslint-disable */
                item[key] = "\'" + item[key];
              }
            })
            return item
          })
          const columns = this.columns.filter((item) => {
            return form.checkKey.indexOf(item.key) !== -1 && !item.type && item.isShow;
          });
          let resultAry = [];
          const deepConcat = (ary, parentTitle) => {
            ary.forEach(item => {
              let itemParentTitle = parentTitle;
              const columnsItem = clone(item);
              if (itemParentTitle) {
                itemParentTitle = itemParentTitle + '-' + item.title
                columnsItem.title = itemParentTitle;
              }
              delete columnsItem.children;
              if (item.children && item.children.length > 0) {
                deepConcat(item.children, itemParentTitle || columnsItem.title)
              } else {
                resultAry.push(columnsItem)
              }
            })
          }
          deepConcat(columns)
          this.$refs.table.exportCsv({
            filename: form.fileName,
            quoted: true,
            columns: resultAry,
            data: data,
          });
        });
      }
      this.autoExpert = true;
      this.exportFlag = false;
    },
    selectAll(type) {
      this.$refs["table"].selectAll(type)
      if (type === false) {
        this.tableSelectItem = [];
      }
    },
    showAllSearch() {
      this.showMoreFlag = !this.showMoreFlag;
      if (!this.showMoreFlag) {
        if (this.pageListData.filter && this.pageListData.filter.length > 0) {
          for (let slot of this.$refs.generateForm.children) {
            slot.style.width = '';
          }
        } else {
          for (let slot of this.$slots.search) {
            if (slot.elm['style']) slot.elm['style'].width = '';
          }
        }
      }
      window.setTimeout(() => {
        this.setSearchBar();
      }, 500)
    },
    click(params, index) {
      if (this.config.lineSelectOverlap === false && this.lineSelect === true) this.$refs["table"].selectAll(false);
      if (this.config.lineSelect === true && this.lineSelect === true) this.$refs["table"].toggleSelect(index);
      this.$emit("on-row-click", params, index);
    },
    region(data, index) {
      this.requestReady = false;
      const {captureKey} = this.pageListData.filter[index];
      const regionKeys = captureKey ? JSON.parse(captureKey) : [];
      regionKeys.forEach((item, index) => {
        this.pageListData.params[item] = data[index] ? data[index].code : '';
      })
      this.requestReady = true;
    },
    async getColumns() {
      this.loading = true;
      let pageListInfo = {};
      if (this.keyID) {
        const userInfo = sessionStorage.getItem('userInfo') ? JSON.parse(sessionStorage.getItem('userInfo')) : {};
        await this.$get('/page-list/Info', {keyId: this.keyID, orgId: userInfo.org_id}).then((res) => {
          if (res.code === 200 && res.data) {
            pageListInfo = res.data || {};
            pageListInfo.params = null;
          }
        });
      }
      ['footer', 'frozenData', 'header', 'isMultiple', 'isPage', 'lineSelect', 'multiColumn', 'overLineSelect', 'showContextMenu'].forEach(item => {
        this.pageListData[item] = pageListInfo[item] == undefined ? this[item] : !!pageListInfo[item];
      });
      ['keyId', 'keyLabel', 'method', 'action', 'noDataText'].forEach(item => {
        this.pageListData[item] = pageListInfo[item] || this[item];
      });
      if (pageListInfo.filter) {
        this.pageListData.params = {}
        pageListInfo.filter.forEach(item => {
          let value;
          switch (item.valueType) {
            case 'number':
              value = item.defaultValue ? Number(item.defaultValue) : null;
              break;
            case 'array':
              value = item.defaultValue ? JSON.parse(item.defaultValue) : [];
              break;
            case 'boolean':
              value = item.defaultValue ? !!item.defaultValue : false;
              break;
            default:
              value = item.defaultValue
              break;
          }
          this.$set(this.pageListData.params, item.key, value)
        })
      } else {
        this.pageListData.params = this.params
      }
      if (this.pageListData.footer && this.pageListData.isPage) {
        this.pageListData.params.page = 1
        this.pageListData.params.limit = this.params.limit || this.config.pageSize;
      }
      this.pageListData.filter = (pageListInfo.filter || [])
      this.pageListData.btnArray = pageListInfo.btnArray ? JSON.parse(pageListInfo.btnArray) : this.btnArray
      this.pageListData.columns = (pageListInfo.columns || this.headerColumns || []).map(({...item}) => {
        item.isShow = item.isShow == undefined ? true : !!item.isShow;
        item.resizable = !!item.resizable;
        item.sortable = !!item.sortable;
        item.tooltip = !!item.tooltip;
        item.ellipsis = item.tooltip;

        if (!item.renderConfig) {
          item.renderConfig = {};
          item.renderConfig.type = item.render ? 'system' : 'none';
        } else {
          if (typeof item.renderConfig === 'string') item.renderConfig = JSON.parse(item.renderConfig);
          switch (item.widthType) {
            case 1:
              item.minWidth = item.width;
              delete item.width;
              break;
            case 2:
              item.maxWidth = item.width;
              delete item.width;
              break;
          }
        }
        return item
      });

      if (this.pageListData.multiColumn) {
        this.pageListData.columns.push({
          width: 75, type: 'hidden', fixed: this.config.fixed, title: '操作', align: 'center', isShow: true, render: (h, params) => {
            return h('Dropdown', {
              props: {
                trigger: 'hover',
                transfer: true,
                placement: this.config.fixed === 'right' ? "bottom-end" : "bottom-start",
              },
              on: {
                'on-visible-change': (visible) => {
                  if (visible) {
                    this.$refs["table"].selectAll(false);
                    this.$refs["table"].toggleSelect(params.index);
                  }
                }
              }
            }, [
              h('a', [
                h('span', '操作'),
                h('Icon', {
                  props: {
                    type: 'md-arrow-dropdown'
                  }
                })
              ]),
              h('DropdownMenu', {slot: 'list'},
                  this.multiBtn.map((item) => {
                    if (Array.isArray(item.children)) {
                      return item.children.map(subItem => {
                        return this.multiColumnDropdownItem(subItem, h)
                      })
                    } else {
                      return this.multiColumnDropdownItem(item, h)
                    }
                  })
              )
            ])
          }
        })
      }
      this.pageListData.columns.forEach((item) => {
        if (item.type === 'index') {
          item.indexMethod = (row) => {
            const index = (this.tableData ? this.tableData : this.List).findIndex(item => item[this.keyLabel] === row[this.keyLabel]);
            return ((this.pageListData.params.page || 1) - 1) * (this.pageListData.params.limit || 1) + 1 + index
          }
        }
        item.id = item.id || "";
        item.isShow = item.isShow === undefined ? true : item.isShow;
        item.align = item.align || "center";
        item.fixed = item.fixed || "none";
        item.resizable = item.resizable || false;
        item.sortable = item.sortable || false;
        if (!item.width && !item.minWidth) item.width = 100;
        let config = item.renderConfig;
        if (config && !item.type && !item.render) {
          if (config.type === 'system') {
            let systemItem = this.headerColumns.filter(sysItem => sysItem.key === item.key);
            item.render = systemItem.length > 0 ? systemItem[0].render : ''
          } else {
            item.render = (h, params) => {
              let value = config.value == undefined ? params.row[item.key] : config.value;
              let key = config.key;
              switch (config.type) {
                case 'baseData':
                  return tooltipRender(h, item.tooltip, getBaseDataName(config.parentName, value));
                case 'image':
                  /* eslint-disable */
                  let url = ''
                  if (config.value == undefined && params.row.fileName) { // 某些列表中存在fileName属性且并非全是imgTypeList内的类型
                    const splitAry = params.row.fileName.split('.');
                    const suffix = (params.row.fileName.suffix || splitAry[splitAry.length - 1]).toUpperCase();
                    if (this.imgTypeList.indexOf(suffix) >= 0) {
                      url = value ? this.$getFileUrl + '/' + value + '?size=100' : ''
                    } else if (this.fileType[suffix]) {
                      url = this.fileType[suffix]
                    } else {
                      url = this.fileType['NONE']
                    }
                  } else {
                    url = value ? this.$getFileUrl + '/' + value + '?size=100' : ''
                  }
                  return h("ys-image", {
                    attrs: {src: url},
                    props: {showModal: config.showModal || false, width: config.width || 40, height: config.height || 40, radius: config.radius || 2}
                  });
                case 'area':
                  if (Array.isArray(key) && key.length > 0) {
                    for (let i = 0; i < key.length; i++) {
                      value = params.row[key[i]];
                      if (value) break;
                    }
                  }
                  return tooltipRender(h, item.tooltip, getRegionByCode(value));
                case 'policyNoDesensitise':
                  return tooltipRender(h, item.tooltip, policyNoDesensitise(value));
                case 'IDDesensitise':
                  return tooltipRender(h, item.tooltip, IDDesensitise(value));
                case 'phoneDesensitise':
                  return tooltipRender(h, item.tooltip, phoneDesensitise(value));
                case 'formatDate':
                  item.renderConfig.fmt = config.fmt || 'yyyy-MM-dd'
                  return tooltipRender(h, item.tooltip, formatDate(value || '', config.fmt || 'yyyy-MM-dd'));
                case 'fixed':
                  item.renderConfig = config || 'yyyy-MM-dd'
                  return tooltipRender(h, item.tooltip, fixed(value || 0, config.unitType || 1, config.num, config.isColor || false));
                case 'gotoDetail':
                  return h('a', {
                    on: {
                      click: () => {
                        window.setTimeout(() => {
                          this.detailFlag = true;
                        })
                      }
                    }
                  }, [tooltipRender(h, item.tooltip, value)])
                default:
                  return tooltipRender(h, item.tooltip, value)
              }
            };
          }
        }
      });
      this.loading = false;
      if (this.header) {
        this.$nextTick(() => {
          this.setSearchBar();
        })
      }
    },
    multiColumnDropdownItem(item, h) {
      return h('DropdownItem', {
        style: item.disabled ? {} : {...item.styles},
        props: {
          disabled: item.disabled
        },
        nativeOn: {
          click: !item.disabled ? (item.type === 'detail' ? () => this.detailFlag = true : item.click) : new Function()
        }
      }, [
        h('Icon', {style: {verticalAlign: 'baseline'}, attrs: {type: item.icon, color: item.disabled ? '' : item.iconColor}}),
        item.targetUrl
            ? h('span', [h('span', {style: {paddingRight: '15px'}}, ' ' + item.title), h('router-link', {
              props: {to: item.targetUrl}, nativeOn: {
                click: (e) => {
                  e.stopPropagation()
                }
              }
            }, '新窗口')])
            : h('span', ' ' + item.title)
      ])
    },
    setSearchBar() {
      const searchBox = this.$refs['searchBox'];
      const searchBtn = this.$refs['searchBtn'];
      const setHeaderBtn = this.$refs['setHeaderBtn'];
      if (!this.$slots.search) {
        this.searchMoreFlag = false;
      }
      if (!searchBox || !searchBtn || !this.$slots.search) return;//搜索按钮区域和搜索项都不存在则不处理
      let searchWidth = searchBox.offsetWidth - setHeaderBtn.offsetWidth - searchBtn.offsetWidth;
      //start向下兼容，判断搜索项是否设置show参数，未设置则显示所有搜索项，有设置则显示带有show的搜索项，且显示展开搜索项按钮。
      this.searchMoreFlag = false;
      let searchEleList = [];
      if (this.pageListData.filter && this.pageListData.filter.length > 0) {
        searchEleList = this.$refs.generateForm.children
      } else {
        searchEleList = this.$slots.search
      }
      for (let item of searchEleList) {
        let slot = this.pageListData.filter && this.pageListData.filter.length ? item : item.elm;
        if (slot && slot.getAttribute && slot.getAttribute && slot.getAttribute('show') !== null) {
          this.searchMoreFlag = true;
          break;
        }
      }
      //end
      //start 判断筛选项宽度是否超过可容纳区域，超过则显示展开按钮
      let searchItemWidth = 0;
      for (let item of searchEleList) {
        let slot = this.pageListData.filter && this.pageListData.filter.length ? item : item.elm;
        if (this.searchMoreFlag === false) {
          if (slot.style) slot.style.display = '';
          searchItemWidth = searchItemWidth + slot.offsetWidth;
        } else {
          let show = !!(slot && slot.getAttribute && slot.getAttribute('show') !== null);
          if (slot.style) slot.style.display = show ? '' : 'none';
          searchItemWidth = show ? (searchItemWidth + slot.offsetWidth) : searchItemWidth;
        }
        if (searchItemWidth > searchWidth || this.showMoreFlag) {
          this.searchMoreFlag = true;
          if (slot.style) slot.style.display = (this.showMoreFlag ? '' : 'none');
          if (slot.style) slot.style.width = (this.showMoreFlag ? '25%' : '');
          if (slot.style) slot.style.minWidth = '250px';
        } else {
          if (slot.style) slot.style.minWidth = '';
        }
      }
      //end
    },
    async reFresh(nextType) {
      await this.getColumns();
      await this.getList(nextType);
    },
    getData(nextType) {
      return new Promise((resolve) => {
        this.loading = true;
        this.List = [];//必须在勿删。防止数据冻结后长期被缓存着。
        let listData = [];
        let SelectedIDs = [] // 需被选中数据
        this.pageListData.params.limit = this.pageListData.params.limit || this.params.limit || this.config.pageSize;
        this.perParams = clone(this.pageListData.params);//备份本次查询参数值，用于对比下次查询参数变化而重置page=1
        this[this.method](this.pageListData.action, this.pageListData.params).then((res) => {
          if (this.showMoreFlag) this.showAllSearch();
          this.loading = false;
          if (this.isEmptySelect) {
            this.tableSelectItem = [];
          }
          // 将原已被选中的数据合并进来并去重
          SelectedIDs = Array.from(new Set(this.tableSelectItem.map(item => item[this.keyLabel]).concat(this.IDs)))
          listData = ((Array.isArray(res.data) ? res.data : res.data.list) || []).map((item, index) => {
            item[this.keyLabel] = item[this.keyLabel] || index;
            if (SelectedIDs.length > 0 && SelectedIDs.indexOf(item[this.keyLabel]) >= 0) {
              item._checked = true;
              // 不存时再push
              if (this.tableSelectItem.every(everyItem => everyItem[this.keyLabel] !== item[this.keyLabel])) {
                this.tableSelectItem.push(item)
              }
            }
            return item
          });
          this.List = (this.frozenData ? Object.freeze(listData) : listData);//冻结数据 提升性能
          if (nextType === 'detail' && SelectedIDs.length > 0) this.detailFlag = true;
          this.pageListData.params.page = res.data.page;
          this.pageListData.params.total = res.data.total;
          this.pageListData.params.limit = res.data.limit;
          this.allData = res.data;
          /*首次访问记录下初始值*/
          if (!this.initParams) {
            this.initParams = clone(this.pageListData.params);
          }
          this.initParams.limit = this.pageListData.params.limit;
          this.$emit("on-ok", res);
          resolve(this.List);
          this.tableTooltip();
          this.summaryMethod({columns: this.columns, data: this.List})
        }).catch(() => {
          this.loading = false;
        });
      });
    },
    /**
     * 列表数据
     * @param nextType getList下一步getData内操作
     * @param isManualSearch 是否按钮手动触发
     * @param isReset 是否重置触发
     */
    async getList(nextType = null, isManualSearch = false, isReset = false) {
      if (!this.pageListData.action) {//如果没action值，需要去查询下表头配置
        await this.getColumns();
      }
      if (!this.pageListData.action) return false;//如果没this.action值，那就不需要查询接口数据了，没法查，是吧！
      Object.keys(this.pageListData.params).forEach(item => {
        //用于对比上次次查询参数变化，变了重置page=1；
        if (['page', 'total'].indexOf(item) === -1 && !comparisonObject(this.pageListData.params[item], this.perParams[item])) {
          this.pageListData.params.page = 1;
        }
      })
      /*判断是否是自定义筛选项且包含区域选择*/
      const regionLen = this.pageListData.filter.filter(item => item.originType === 'region' && item.defaultValue).length;
      if (!isReset) this.$emit('on-search', this.pageListData.params, isManualSearch || false)
      if (regionLen > 0) {
        let timer = window.setInterval(() => {
          if (this.requestReady) {/*区域选择数据初始化完成*/
            window.clearInterval(timer);
            this.getData(nextType);
          }
        }, 100)
      } else {
        await this.getData(nextType);
      }
    },
    widthResize() {
      this.tableTooltip()
    },
    tableTooltip() {
      window.setTimeout(() => {
        let tooltip = document.getElementsByClassName('tooltip');
        for (let item of tooltip) {
          let tooltipSpan = item.childNodes[0];
          if (tooltipSpan['offsetHeight'] > item['offsetHeight'] || tooltipSpan['offsetWidth'] > item['offsetWidth']) {
            new Vue({
              components: {Tooltip},
              render: (h) => {
                return h('Tooltip', {
                  style: {
                    overflow: 'hidden',
                    'text-overflow': 'ellipsis',
                    'line-height': '28px',
                    'white-space': 'nowrap',
                    'width': '100%',
                  },
                  props: {
                    'max-width': 300,
                    content: item.textContent
                  }
                }, item.textContent)
              }
            }).$mount(item.childNodes[0])
          }
        }
      }, 2000)
    },
    checkItem(selection) {
      if (!this.isEmptySelect) {
        // 先将当前页面存在数据从已被选中数据中清除，而后再根据当前是否存在被选中的数据决定是否合并数据
        this.tableSelectItem = this.tableSelectItem.filter(selectItem => {
          return (this.tableData || this.List).every(item => selectItem[this.keyLabel] !== item[this.keyLabel])
        })
        if (selection.length > 0) {
          this.tableSelectItem = this.tableSelectItem.concat(selection)
        }
      } else {
        this.tableSelectItem = selection;
      }
    },
    handleContextMenu(row) {
      if (!this.pageListData.showContextMenu) return
      this.$nextTick(() => {
        let target = document.getElementsByClassName('ivu-table-context-menu');
        target[0].style.left = target[0].offsetLeft + 90 + 'px';
        target[0].style.top = target[0].offsetTop - 18 + 'px';
      })

      const index = (this.tableData ? this.tableData : this.List).findIndex(item => item[this.keyLabel] === row[this.keyLabel]);
      if (this.tableSelectItem.findIndex(item => item[this.keyLabel] === row[this.keyLabel]) < 0) {
        this.$refs["table"].selectAll(false);
        this.$refs["table"].toggleSelect(index)
      }
    },
    selectCancel(params, row) {
      this.$emit("on-select-cancel", params, row);
    },
    selectAllCancel(params) {
      this.$emit("on-select-all-cancel", params);
    },
    dblclick(params, index) {
      this.$emit("on-row-dblclick", params, index);
    },
    pageChange(pageIndex) {
      this.pageListData.params.page = pageIndex;
      this.getList();
    },
    pageSizeChange(pageSize) {
      this.pageListData.params.limit = pageSize;
      this.getList();
    },
    rowClassFn(row) {
      if (row._isChecked) {
        return "tableRowCheckBg";
      }
      return "";
    }
  },
  beforeDestroy() {
    window.onresize = null;
    if (!this.config.scroll) window.removeEventListener('resize', this.onWindowResize, false);
  },
}
</script>

<template>
  <div>
    <ul :class="classCls" v-if="defaultFileList.length>0">
      <li :class="itemCls" :style="{'flex-basis':gutter?'calc('+(100/gutter)+'% - 15px)':(width+'px'),height:height+'px'}" v-for="(item,index) in defaultFileList" :key="index">
        <div>
          <YSImage :data-src="item.url" :src="item.url+'?size=100'" :width="width" :height="height" v-if="imgTypeList.indexOf(item.suffix)>=0" @click.native="view(item)"></YSImage>
          <YSImage :src="fileType[item.suffix]" :width="width" :height="height" v-else-if="fileType[item.suffix]"/>
          <YSImage :src="fileType['NONE']" :width="width" :height="height" v-else/>
        </div>
        <div :class="infoCls" v-if="type==='all'">
          <h3 :class="fileNameCls">{{ item.sourceName || item.fileName }}</h3>
          <p>{{ item.createdAt }}</p>
          <p>{{ item.desc }}</p>
        </div>
        <div v-if="type==='all'">
          <a href="javascript:" :class="viewCls" @click="view(item)" title="预览" v-if="type==='all'&&['DOC','DOCX','XLS','XLSX','JPG', 'GIF', 'PNG', 'JPEG', 'PDF', 'MP3', 'MP4', 'PPT', 'PPTX'].indexOf(item.suffix)>=0">
            <Icon type="ios-folder-open"/>
            预览
          </a>
          <span v-else>无法预览</span>
          <a @click="downFile(item)" href="javascript:" :class="downCls" title="下载" v-if="type==='all'">
            <Icon type="md-cloud-download"/>
            下载
          </a>
        </div>
      </li>
    </ul>
    <div class="noData" v-else>{{ noData }}</div>
    <ys-modal footerHide v-model="viewFlag" :width="80" title="预览">
      <file-view :file="file"></file-view>
    </ys-modal>
    <!-- <ys-modal v-model="viewAudioFlag" :width="30" title="音频预览">
      <audio-play :audioFilePath="audioFilePath"></audio-play>
    </ys-modal>
    <ys-modal v-model="viewVideoFlag" :width="30" title="视频预览">
      <video-play :src="videoFilePath"></video-play>
    </ys-modal> -->
  </div>
</template>


<script>
import download from "downloadjs"
import {clone} from "../../lib";
import FileView from "../ys-file-list/view.vue";

const prefixCls = 'ys-file-list';

export default {
  name: "ys-file-list",
  components: {
    FileView,
    YSImage: () => import('../ys-image/index.vue')
  },
  props: {
    fileList: {type: Array, default: () => []},
    fileIds: {type: Array, default: () => []},
    type: {type: String, default: 'all'},//all 显示全部，onlyFile 仅显示文件图标/图片
    width: {type: Number, default: 150},
    height: {type: Number, default: 150},
    gutter: {type: Number, default: 2},
    noData: {type: String, default: '暂无附件数据！'},
  },
  computed: {
    classCls() {
      return prefixCls;
    },
    itemCls() {
      return [`${prefixCls}-item`];
    },
    infoCls() {
      return [`${this.itemCls}-info`];
    },
    fileNameCls() {
      return [`${this.infoCls}-fileName`];
    },
    downCls() {
      return [`${this.itemCls}-down`];
    },
    viewCls() {
      return [this.itemCls + '-view'];
    },
  },
  watch: {
    fileList: function () {
      this.getSuffix();
    },
    fileIds: function () {
      this.getFileList();
    },
  },
  data() {
    return {
      viewFlag: false,
      viewAudioFlag: false,
      audioFilePath: '',
      viewVideoFlag: false,
      videoFilePath: '',
      file: {},
      defaultFileList: [],
      fileType: {
        AVI: require('../../assets/images/AVI.png'),
        CDR: require('../../assets/images/CDR.png'),
        CSS: require('../../assets/images/CSS.png'),
        DOC: require('../../assets/images/DOC.png'),
        DOCX: require('../../assets/images/DOCX.png'),
        PDF: require('../../assets/images/PDF.png'),
        MOV: require('../../assets/images/MOV.png'),
        HTML: require('../../assets/images/HTML.png'),
        MP3: require('../../assets/images/MP3.png'),
        MP4: require('../../assets/images/MP4.png'),
        PHP: require('../../assets/images/PHP.png'),
        PPT: require('../../assets/images/PPT.png'),
        PPTX: require('../../assets/images/PPTX.png'),
        EPS: require('../../assets/images/EPS.png'),
        PSD: require('../../assets/images/PSD.png'),
        RAR: require('../../assets/images/RAR.png'),
        TTF: require('../../assets/images/TTF.png'),
        TXT: require('../../assets/images/TXT.png'),
        XLS: require('../../assets/images/XLS.png'),
        XLSX: require('../../assets/images/XLSX.png'),
        ZIP: require('../../assets/images/ZIP.png'),
        NONE: require('../../assets/images/unknown.png'),
      },
      imgTypeList: ['JPG', 'GIF', 'PNG', 'JPEG'],
    }
  },
  mounted() {
    this.defaultFileList = clone(this.fileList);
    if (this.fileIds.length > 0) {
      this.getFileList()
    }else{
      this.getSuffix()
    }
  },
  methods: {
    view(item) {
      if(item.suffix === 'MP3') {
        this.audioFilePath = item.url
        this.viewAudioFlag = true
        return
      }
      if(item.suffix === 'MP4') {
        this.videoFilePath = item.url
        this.viewVideoFlag = true
        return
      }
      if (this.imgTypeList.indexOf(item.suffix) >= 0) {
        this.$viewerApi({images: [item.url]});
      } else {
        this.file = item;
        this.viewFlag = true;
      }
    },
    downFile(item){
      const request = new XMLHttpRequest()
      request.open('GET', item.url)
      request.responseType = 'blob'
      request.onreadystatechange = () => {
        if (request.readyState === XMLHttpRequest.DONE && request.status === 200) {
          console.log(request.response)
          download(request.response, item.fileName)
        }
      }
      request.send()
    },
    getFileList() {
      this.$get('/resource/GetFileByIds', {ids: this.fileIds}).then(res => {
        this.defaultFileList=this.defaultFileList.concat(res.data||[]);
        this.getSuffix();
      });
    },
    getSuffix() {
      let splitAry;
      this.defaultFileList.forEach(item => {
        if (item.fileName) {
          splitAry = item.fileName.split('.');
          item.suffix = (item.suffix || splitAry[splitAry.length - 1]).toUpperCase();
        }
      })


    },
  }
}
</script>

<style scoped>

</style>
<template>
  <div :class="classCls">
    <Spin size="large" fix v-if="spinShow">
      <div style="padding: 30px 0;font-size: 14px">
        <Icon type="ios-loading" size=30 class="spin-icon-load"></Icon>
        <div style="padding-top: 10px;">加载中，请稍后...</div>
      </div>
    </Spin>
    <Row :gutter="20">
      <Col :span="manager?8:0">
        <Form ref="form" class="editForm twoPartsEditForm" :model="form" :rules="ruleValidate" :label-width="80" inline :style="{maxHeight:'calc(100vh - 110px)'}" style="overflow-y: auto">
          <template v-if="manager">
            <h1 :class="titleCls">自定义列表配置</h1>
            <FormItem label="名称" prop="name" style="width: 100%">
              <Input v-model="form.name" placeholder="名称"/>
            </FormItem>
            <FormItem label="keyID" prop="keyId" style="width: 100%">
              <Input v-model="form.keyId" disabled placeholder="名称"/>
            </FormItem>
            <FormItem label="索引值" prop="keyLabel" v-if="manager" style="width: 30%">
              <Input v-model="form.keyLabel" placeholder="索引值"/>
            </FormItem>
            <FormItem label="接口地址" prop="action" v-if="manager" style="width: 70%">
              <Select v-model="form.method" placeholder="请求方法" style="width: 20%">
                <Option v-for="(item,index) in baseData['表头配置请求类型']" :value="item.value" :key="index">{{ item.name }}</Option>
              </Select>
              <Input v-model="form.action" style="width: 80%" placeholder="接口地址"></Input>
            </FormItem>
            <FormItem label="显示头部" prop="header" v-if="manager" style="width: 33%">
              <i-switch :trueValue="1" :falseValue="0" v-model="form.header"></i-switch>
            </FormItem>
            <FormItem label="显示底部" prop="footer" v-if="manager" style="width: 33%">
              <i-switch :trueValue="1" :falseValue="0" v-model="form.footer"></i-switch>
            </FormItem>
            <FormItem label="显示翻页" prop="isPage" v-if="manager" style="width: 33%">
              <i-switch :trueValue="1" :falseValue="0" v-model="form.isPage"></i-switch>
            </FormItem>
            <FormItem label="启用右键" prop="showContextMenu" v-if="manager" style="width: 33%">
              <i-switch :trueValue="1" :falseValue="0" v-model="form.showContextMenu"></i-switch>
            </FormItem>
            <FormItem label="显示操作栏" :labelWidth="90" v-if="manager" prop="multiColumn" style="width: 33%">
              <i-switch :trueValue="1" :falseValue="0" v-model="form.multiColumn"></i-switch>
            </FormItem>
            <FormItem label="按钮设置" v-if="manager" prop="btnArray" style="width: 100%">
              <Select v-model="form.btnArray" placeholder="按钮设置" class="selectMultiple" style="width: 100%" multiple>
                <Option v-for="(item,index) in baseData['表头配置按钮配置']" :disabled="item.value==='set'" :value="item.value" :key="index">{{ item.name }}</Option>
              </Select>
            </FormItem>
            <FormItem label="备注" prop="note" style="width: 100%">
              <Input v-model="form.note" type="textarea" style="width: 100%" :autosize="{minRows: 2,maxRows: 3}" placeholder="备注"></Input>
            </FormItem>
          </template>
        </Form>
      </Col>
      <Col :span="manager?16:24">
        <Tabs v-model="tabs" @on-click="tabsChange" :animated="false">
          <TabPane label="表头配置" name="1">
            <div :class="headerCls">
              <div :class="headerSwitchItemCls" style="flex-basis: 60px;">拖动区</div>
              <div :class="headerSwitchItemCls">显示</div>
              <div :class="headerItemCls" :style="{flexBasis: '120px',flex: 1}"> 抬头</div>
              <div :class="headerItemCls" style="flex-basis: 150px;" v-if="manager">宽度</div>
              <div :class="headerItemCls" v-if="manager">对其方式</div>
              <div :class="headerItemCls" v-if="manager">固定方式</div>
              <div :class="headerSwitchItemCls" v-if="manager">列宽调整
                <Tooltip placement="top" max-width="180" content="只有列宽为固定模式时才可以列宽调整！">
                  <Icon type="md-alert" color="#ff6600" size="14"/>
                </Tooltip>
              </div>
              <div :class="headerSwitchItemCls" v-if="manager">
                <Tooltip placement="top" max-width="180" content="开启后会影响当前表格显示性能(卡顿现象)，请少量开启。开启时文本宽度超过单元格宽度会用省略号代替。">
                  不换行
                  <Icon type="md-alert" color="#ff6600" size="14"/>
                </Tooltip>
              </div>
              <div :class="headerSwitchItemCls" v-if="manager">显示排序</div>
            </div>
            <div id="tableHeader" :class="tableColumnCls">
              <Alert type="warning">如果您不知道如何配置，请将此串编码复制后告知运维人员。编码：{{ keyID }} <a href="javascript:;" class="copy" :data-clipboard-text="'keyID:'+keyID+'，版本号：'+(form.versions||'无')" @click="copy">复制</a></Alert>
              <Alert type="error" v-if="noSaveColumns.length>0">该自定义列包含不可配置列项：【{{ noSaveColumns.join('，') }}】，保存后将失效，请注意！</Alert>
              <draggable v-model="form.columns" handle=".mover" :chosenClass="tableColumnChosenCls" filter=".forbid" @end="onEnd" force-fallback="true" group="people" animation="100">
                <transition-group>
                  <div :class="columnCls" :key="item.key" v-for="(item, index) in form.columns">
                    <div :class="headerSwitchItemCls" class="mover" style="flex-basis: 60px;">
                      <Icon type="ios-move" size="22"/>
                    </div>
                    <div :class="headerSwitchItemCls">
                      <i-switch :trueValue="1" :falseValue="0" v-model="item.isShow" size="small"></i-switch>
                    </div>
                    <div :class="headerItemCls" :style="{flexBasis: '120px',flex: 1}">
                      <Input v-model="item.title" :disabled="item.type==='selection'" :readonly="!manager" size="small" placeholder="抬头" :style="{width: ['formatDate','fixed'].indexOf(item.renderConfig.type) >=0?'40%':'95%'}"/>
                      <Select transfer v-model="item.renderConfig.fmt" placeholder="日期格式" size="small" style="width: 55%; margin-left: 1%" v-if="item.renderConfig.type === 'formatDate'">
                        <Option v-for="item in ['yyyy','yyyy年','yyyy-MM','yyyy年MM月','yyyy-MM-dd','yyyy年MM月dd日','yyyy-MM-dd hh:mm:ss','yyyy年MM月dd日 hh点mm分ss秒','yyyy-MM-dd hh:mm','yyyy年MM月dd日 hh点mm分']" :key="item" :value="item">{{ item }}</Option>
                      </Select>
                      <Select transfer v-model="item.renderConfig.unitType" placeholder="单位标记" size="small" style="width: 55%; margin-left: 1%" v-if="item.renderConfig.type === 'fixed'">
                        <Option v-for="item in unitTypeList" :key="item.value" :value="item.value">{{ item.name }}</Option>
                      </Select>
                    </div>
                    <div :class="headerItemCls" style="flex-basis: 150px;" v-if="manager">
                      <Select transfer v-model="item.widthType" @on-change="widthChange(index)" placeholder="宽度类型" size="small" style="width: 45%;%">
                        <Option v-for="item in baseData['表头配置宽度类型']" :key="item.value" :value="item.value">{{ item.name }}</Option>
                      </Select>
                      <Input v-model="item.width" size="small" placeholder="空则自适应剩余宽度" style="width: 35%; margin-left: 1%"/>
                    </div>
                    <div :class="headerItemCls" v-if="manager">
                      <Select transfer v-model="item.align" size="small" style="width: 90%">
                        <Option v-for="item in baseData['表头配置排列方式']" :key="item.value" :value="item.value">{{ item.name }}</Option>
                      </Select>
                    </div>
                    <div :class="headerItemCls" v-if="manager">
                      <Select transfer v-model="item.fixed" size="small" style="width: 90%">
                        <Option v-for="item in baseData['表头配置固定类型']" :key="item.value" :value="item.value">{{ item.name }}</Option>
                      </Select>
                    </div>
                    <div :class="headerSwitchItemCls" v-if="manager">
                      <i-switch :trueValue="1" :falseValue="0" v-model="item.resizable" :disabled="item.type==='selection'||item.widthType!==0" size="small"></i-switch>
                    </div>
                    <div :class="headerSwitchItemCls" v-if="manager">
                      <i-switch :trueValue="1" :falseValue="0" v-model="item.tooltip" :disabled="item.type==='selection'" size="small"></i-switch>
                    </div>
                    <div :class="headerSwitchItemCls" v-if="manager">
                      <i-switch :trueValue="1" :falseValue="0" v-model="item.sortable" :disabled="item.type==='selection'" size="small"></i-switch>
                    </div>
                  </div>
                </transition-group>
              </draggable>
            </div>
          </TabPane>
          <TabPane name="2" label="筛选项配置" v-if="(filterItemList || []).length !== 0">
            <div :class="headerCls">
              <div :class="headerSwitchItemCls" style="flex-basis: 60px;">拖动区</div>
              <div :class="headerItemCls" :style="{flex: '1'}">筛选项名称</div>
              <div :class="headerItemCls" style="flex-basis: 250px;" v-if="manager">默认值</div>
              <div :class="headerItemCls" style="flex-basis: 150px;">宽度</div>
              <div :class="headerItemCls" :style="{flex: '1'}">提示文字</div>
              <div :class="headerSwitchItemCls">不折叠</div>
              <div :class="headerSwitchItemCls">操作</div>
            </div>
            <Alert type="error">当您新增筛选项后，初始的筛选项配置将失效，请注意！</Alert>
            <div id="tableFilter" :class="tableFilterCls">
              <draggable v-model="form.filter" handle=".mover" :chosenClass="tableColumnChosenCls" filter=".forbid" @end="onEnd" force-fallback="true" group="people" animation="100">
                <transition-group>
                  <div>
                    <div :class="columnCls" :key="(item.key+index)" v-for="(item, index) in form.filter">
                      <div :class="headerSwitchItemCls" class="mover" style="flex-basis: 60px;">
                        <Icon type="ios-move" size="22"/>
                      </div>
                      <div :class="headerItemCls" :style="{flex: '1'}">
                        <Select transfer v-model="item.key" @on-change="filterChange(index)" size="small" style="width: 45%">
                          <Option v-for="(item,keyIndex) in filterItemList" :key="keyIndex" :value="item.key">{{ item.label }}</Option>
                        </Select>
                        <Input v-model="item.label" size="small" placeholder="筛选项名称" style="width: 45%;margin-left: 1%"/>
                      </div>
                      <div :class="headerItemCls" style="flex-basis: 250px;" v-if="manager">
                        <Input v-model="item.defaultValue" clearable size="small" placeholder="默认值" style="width: 90%" v-if="item.originType==='input'"/>
                        <ys-region clearable changeOnSelect @on-change="region($event,index)" v-model="item.defaultValue" size="small" placeholder="默认值" style="width: 90%;margin: 0 auto;" v-else-if="item.originType==='region'"/>
                        <Select transfer clearable v-model="item.defaultValue" placeholder="可搜索" size="small" filterable style="width: 90%; " v-else-if="item.originType === 'baseData'">
                          <Option v-for="item in baseData[item.originValue]" size="small" :key="item.value" :value="item.value">{{ item.name }}</Option>
                        </Select>
                        <ys-date-picker clearable v-model="item.defaultValue" style="width: 90%" size="small" placeholder="默认值" v-else-if="item.originType==='dateTimer'"></ys-date-picker>
                        <Checkbox v-model="item.defaultValue" border v-else-if="item.originType==='checkBox'">{{ item.label }}</Checkbox>
                      </div>
                      <div :class="headerItemCls" style="flex-basis: 150px;">
                        <Input v-model="item.width" size="small" placeholder="宽度" style="width: 90%"/>
                      </div>
                      <div :class="headerItemCls" :style="{flex: '1'}">
                        <Input v-model="item.placeholder" size="small" placeholder="提示文字" style="width: 90%"/>
                      </div>
                      <div :class="headerSwitchItemCls">
                        <i-switch :trueValue="1" :falseValue="0" size="small" v-model="item.show"></i-switch>
                      </div>
                      <div :class="headerSwitchItemCls">
                        <Button icon="ios-trash-outline" size="small" title="删除" @click="delFilter(item, index)"></Button>
                      </div>
                    </div>
                  </div>
                </transition-group>
              </draggable>
            </div>
          </TabPane>
        </Tabs>
      </Col>
    </Row>
    <div :class="footerCls">
      <div :class="footerOperationCls">
        <Dropdown transfer style="float: left" @on-click="add" v-if="tabs==='1'&&1===2">
          <Button type="primary" icon="md-add" @click="add('')">新增列项</Button>
          <DropdownMenu slot="list">
            <DropdownItem name="">常规列项</DropdownItem>
            <DropdownItem name="selection" v-if="form.columns.filter(item=>item.type==='selection').length<=0">选择框列项</DropdownItem>
            <DropdownItem name="index" v-if="form.columns.filter(item=>item.type==='index').length<=0">序号列项</DropdownItem>
          </DropdownMenu>
        </Dropdown>
        <Button type="primary" icon="md-add" @click="addFilter" v-else-if="tabs==='2'">新增筛选项</Button>
        <!--        <Button type="primary" @click="delColumn">删除此配置</Button>&nbsp;&nbsp;-->
      </div>
      <div :class="footerCloseCls">
        <Button type="primary" @click="save()" style="margin-right: 5px">确定并保存</Button>
        <Button @click="close">取消</Button>
      </div>
    </div>
  </div>
</template>
<script>
import draggable from "vuedraggable";
import ysRegion from '../ys-region/index'
import {clone} from "../../lib/index";
import Clipboard from 'clipboard';

const prefixCls = 'ys-page-list-column';
export default {
  components: {
    ysRegion,
    draggable,
  },
  props: {
    manager: {type: Boolean, default: false},
    action: {type: String, default: null},
    pageListId: {type: [String, Number], default: null},
    keyID: {type: String, default: null},
    method: {type: String, default: null},
    params: {type: Object, default: null},
    columns: {type: Array, default: () => []},
    btnArray: {type: Array, default: () => []},
  },
  computed: {
    baseData() {
      return window.baseData
    },
    classCls() {
      return [prefixCls]
    },
    titleCls() {
      return [
        `${prefixCls}-title`,
      ]
    },
    columnCls() {
      return [
        `${prefixCls}-column`,
      ]
    },
    tableColumnCls() {
      return [
        `${prefixCls}-tableColumn`,
      ]
    },
    tableFilterCls() {
      return [
        `${prefixCls}-tableFilter`,
      ]
    },
    tableColumnChosenCls() {
      return [
        `${this.tableColumnCls}-chosen`,
      ]
    },
    tableFilterChosenCls() {
      return [
        `${this.tableFilterCls}-chosen`,
      ]
    },
    footerCls() {
      return [
        `${prefixCls}-footer`,
      ]
    },
    footerCloseCls() {
      return [
        `${this.footerCls}-close`,
      ]
    },
    footerOperationCls() {
      return [
        `${this.footerCls}-operation`,
      ]
    },
    headerCls() {
      return [
        `${prefixCls}-header`,
      ]
    },
    headerItemCls() {
      return [
        `${this.headerCls}-item`,
      ]
    },
    headerSwitchItemCls() {
      return [
        `${this.headerCls}-item`,
        `${this.headerCls}-switch`,
      ]
    },
    headerValueTypeItemCls() {
      return [
        `${this.headerCls}-item`,
        `${this.headerCls}-valueType`,
      ]
    },
  },
  data() {
    return {
      ruleValidate: {
        name: [
          {required: true, message: '请填写名称', trigger: 'blur'}
        ],
      },
      tabs: '1',
      pageListData: {},
      spinShow: false,
      noSaveColumns: [],
      filterItemList: [],
      keysList: [],
      headerList: [],
      unitTypeList: [
        {name: '㎡', value: 2},
        {name: '月', value: 3},
        {name: '人', value: 4},
        {name: '%', value: 5},
        {name: '日', value: 6},
        {name: '‰', value: 7},
        {name: '天', value: 8},
        {name: '元', value: 9},
        {name: '万元', value: 10},
        {name: 'm', value: 11},
      ],
      form: {
        id: "",
        action: "",
        btnArray: [],
        columns: [],
        filter: [],
        footer: 1,
        frozenData: 1,
        platFormType: this.$platFormType,
        header: 1,
        isMultiple: 1,
        orgId: 0,
        isPage: 1,
        versions: "",
        keyId: "",
        keyLabel: "id",
        lineSelect: 1,
        method: "",
        multiColumn: 1,
        name: "",
        noDataText: "暂无数据！",
        note: "",
        overLineSelect: 0,
        params: "",
        showContextMenu: 1,
      }
    }
  },
  async mounted() {
    this.form.name = (this.$route.meta ? this.$route.meta.title : '') + '配置';
    await this.init();
  },
  methods: {
    copy() {
      let clipboard = new Clipboard('.copy')
      clipboard.on('success', () => {
        this.$Message.success('复制成功！');
        // 释放内存
        clipboard.destroy();
      })
      clipboard.on('error', () => {
        // 不支持复制
        this.$Message.error('该浏览器不支持自动复制')
        // 释放内存
        clipboard.destroy()
      })
    },
    async initKeys() {
      await this.$post('/page-list/InsertKeys', this.form).then(res => {
        if (res.code === 200) this.getPageListBaseData();
      })
    },
    async getPageListBaseData() {
      await this.$all([
        this.$get('/pageListKeys/List', {keyId: this.keyID, limit: -1}),
        this.$get('/filterItem/List', {keyId: this.keyID, limit: -1})
      ]).then(res => {
        this.keysList = res[0].data.list
        if (!this.keysList) {
          this.initKeys();
        } else {
          this.filterItemList = (res[1].data.list || []).map(item => {
            switch (item.valueType) {
              case 'number':
                item.defaultValue = item.defaultValue ? Number(item.defaultValue) : null;
                break;
              case 'array':
                item.defaultValue = item.defaultValue ? JSON.parse(item.defaultValue) : [];
                break;
              case 'boolean':
                item.defaultValue = item.defaultValue ? !!item.defaultValue : false;
                break;
            }
            return item;
          });
          this.form.columns = this.form.columns.map(({...item}) => {
            if (!item.listKeyid && item.key) {
              let filterKey = this.keysList.filter(filterItem => filterItem.key === item.key);
              item.listKeyid = filterKey.length > 0 ? filterKey[0].ID : 0;
            }
            return item;
          });
          this.keysList.forEach(item => {
            let filterColumn = this.form.columns.filter(filterItem => String(item.key) === String(filterItem.key))
            if (filterColumn.length <= 0) {
              let renderConfig = {};
              renderConfig['type'] = item.type;
              switch (renderConfig.type) {
                case 'baseData':
                  renderConfig.parentName = item.type_params ? JSON.parse(item.type_params).parentName : '';
                  break;
                case 'formatDate':
                  renderConfig.fmt = item.type_params ? JSON.parse(item.type_params).fmt : '';
                  break;
              }
              this.form.columns.push({
                align: "center",
                children: null,
                tooltip: 0,
                listKeyid: item.ID,
                ellipsis: 0,
                fixed: "none",
                id: "",
                __id: new Date().getTime(),
                isShow: 0,
                widthType: 0,
                key: item.key,
                renderConfig: renderConfig,
                resizable: 0,
                sortable: 0,
                title: item.keyName,
                type: null,
                width: 100,
              });
            }
          })
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    async init() {
      this.spinShow = true
      let columnsCurrent = []
      const userInfo = sessionStorage.getItem('userInfo') ? JSON.parse(sessionStorage.getItem('userInfo')) : {};
      await this.$get(this.manager ? '/page-list/InfoById' : '/page-list/Info', this.manager ? {id: this.pageListId} : {keyId: this.keyID, orgId: userInfo.org_id}).then((res) => {
        if (res.code === 200) {
          if (res.data) {
            this.pageListData = res.data
            this.pageListData.filter = res.data.filter || [];
            this.pageListData.filter.forEach(item => {
              switch (item.valueType) {
                case 'number':
                  item.defaultValue = item.defaultValue ? Number(item.defaultValue) : null;
                  break;
                case 'array':
                  item.defaultValue = item.defaultValue ? JSON.parse(item.defaultValue) : [];
                  break;
                case 'boolean':
                  item.defaultValue = item.defaultValue ? !!item.defaultValue : false;
                  break;
              }
            })
            this.pageListData.id = this.pageListData.id || this.pageListData.ID;
          } else {
            columnsCurrent = clone(this.columns).map(({...item}) => {
              item.isShow = item.isShow === true ? 1 : 0;
              item.resizable = item.resizable === true ? 1 : 0;
              item.sortable = item.sortable === true ? 1 : 0;
              return item
            })
          }
        }
      });
      Object.keys(this.form).forEach(item => {
        this.form[item] = this.pageListData[item] == undefined ? this.form[item] : clone(this.pageListData[item]);
      })
      this.form.orgId = this.form.orgId || userInfo.org_id || 0;
      this.form.keyId = this.form.keyId || this.keyID;
      this.form.action = this.form.action || this.action;
      this.form.method = this.form.method || this.method;
      this.form.btnArray = Array.isArray(this.form.btnArray) ? this.btnArray : JSON.parse(this.form.btnArray);
      this.form.columns = (this.pageListData['columns'] || columnsCurrent).filter(({...item}) => item.type !== 'hidden').map(({...item}, index) => {
        if (!item.widthType) {
          item.widthType = item.width ? 0 : (item.minWidth ? 1 : 2);
        }
        item.order = index + 1;
        item.orderId = index + 1;
        item.renderConfig = typeof (item.renderConfig) === 'string' ? JSON.parse(item.renderConfig) : item.renderConfig;
        item.width = item.width || item.minWidth || item.maxWidth;
        return item;
      });
      await this.getPageListBaseData();
      this.spinShow = false;
    },
    tabsChange(name) {
      this.tabs = name
    },
    region(event, index) {
      this.form.filter[index].defaultValue = event.length > 0 ? event[event.length - 1].code : '';
    },
    close() {
      this.$emit('on-close')
    },
    filterChange(index) {
      let currentFilter = this.filterItemList.filter(item => item.key === this.form.filter[index].key)[0];
      Object.keys(this.form.filter[index]).forEach(item => {
        this.form.filter[index][item] = currentFilter[item];
      })
    },
    typeChange(index) {
      let currentKey = this.keysList.filter(item => item.key === this.form.columns[index].key)[0];
      this.form.columns[index].renderConfig.type = currentKey.type;
      this.form.columns[index].title = currentKey.key_name;
      switch (currentKey.type) {
        case 'baseData':
          this.form.columns[index].renderConfig.parentName = JSON.parse(currentKey.type_params).parentName;
          break;
        case 'formatDate':
          this.form.columns[index].renderConfig.fmt = JSON.parse(currentKey.type_params).fmt;
          break;
      }
    },
    onEnd() {
      this.form.columns.forEach((item) => {
        (item.children || []).forEach((subItem) => {
          subItem.fixed = "none";
        });
      });
    },
    add(type) {
      this.form.columns.push({
        align: "center",
        children: null,
        tooltip: 0,
        ellipsis: 0,
        fixed: "none",
        id: "",
        __id: new Date().getTime(),
        isShow: 1,
        widthType: 0,
        key: "",
        renderConfig: {type: 'none'},
        resizable: 0,
        sortable: 0,
        title: type === 'index' ? '序号' : (type === 'selection' ? '选择框' : ''),
        type: type || null,
        width: 100,
      });
      this.$nextTick(() => {
        let container = document.querySelector('#tableHeader');
        container.scrollTop = container.scrollHeight
      })
    },
    addFilter() {
      if ((this.filterItemList || []).length === 0) {
        this.$Notice.warning({
          title: "提示！",
          desc: '当前配置暂未开放，请耐心等待！'
        });
        return;
      }
      this.form.filter.push({
        id: '',
        captureKey: '',
        clearable: 1,
        defaultValue: '',
        key: '',
        label: '',
        limit: 1,
        keyId: '',
        originType: 'input',
        originValue: '',
        placeholder: '请选择',
        valueType: 'string',
        width: 120,
        order: 0,
        show: 0,
      });
      this.$nextTick(() => {
        let container = document.querySelector('#tableHeader');
        container.scrollTop = container.scrollHeight
      })
    },
    delColumn() {
      this.$Modal.confirm({
            title: "提示",
            content: "确认要删除此配置么？",
            okText: "确认",
            cancelText: "取消",
            onOk: () => {
              this.$post('/page-list/Remove', {id: this.pageListData.ID}).then((res) => {
                this.spinShow = false
                if (res.code === 200) {
                  this.close();
                  this.$emit('on-ok');
                }
              }).catch(() => {
                this.spinShow = false
              })
            },
          }
      )
    },
    delFilter(item, index) {
      this.$Modal.confirm({
            title: "提示",
            content: "确认要删除这条记录么？",
            okText: "确认",
            cancelText: "取消",
            onOk: () => {
              this.form.filter.splice(index, 1);
            },
          }
      )
    },
    del(item, index) {
      this.$Modal.confirm({
            title: "提示",
            content: "确认要删除这条记录么？",
            okText: "确认",
            cancelText: "取消",
            onOk: () => {
              this.form.columns.splice(index, 1);
            },
          }
      )
    },
    widthChange(index) {
      this.form.columns[index].resizable = this.form.columns[index].widthType !== 0 ? false : this.form.columns[index].resizable;
    },
    async compare() {
      const userInfo = sessionStorage.getItem('userInfo') ? JSON.parse(sessionStorage.getItem('userInfo')) : {};
      await this.$get('/page-list/Info', {keyId: this.keyID, orgId: userInfo.org_id}).then((res) => {
        if (res.code === 200 && res.data) {
          if (this.form.id && this.form.id !== res.data.ID) {
            this.$Modal.confirm({
                  title: "提示",
                  content: "已经有最新的配置数据，是否仍要保存？",
                  okText: "确认",
                  cancelText: "取消",
                  onOk: () => {
                    this.save(true);
                  },
                  onCancel: () => {
                    this.close();
                    this.$emit('on-ok');
                  }
                }
            );
          } else {
            this.save(true);
          }
        } else {
          this.save(true);
        }
      }).catch(() => {
        this.spinShow = false;
      })
    },
    async save(noCompareFlag) {
      this.spinShow = true
      if (!this.manager && !noCompareFlag) {
        await this.compare();
        return false;
      }
      this.$refs['form'].validate((valid) => {
        if (valid) {
          let form = clone(this.form);
          form.btnArray = JSON.stringify(form.btnArray);
          form.params = '';
          form.columns.map((item, index) => {
                item.orderId = index + 1;
                if (typeof item.defaultValue !== 'string') item.defaultValue = String(item.defaultValue);
                item.renderConfig = item.renderConfig ? JSON.stringify(item.renderConfig) : '{}';
                return item;
              }
          );
          form.filter.map((item, index) => {
                item.ID = '';
                item.order = index + 1;
                return item;
              }
          );

          this.$post(this.manager ? '/page-list/EditByManager' : '/page-list/Add', form).then((res) => {
            this.spinShow = false;
            if (res.code === 200) {
              this.close();
              this.$emit('on-ok');
            }
          }).catch(() => {
            this.spinShow = false
          })
        } else {
          this.$Notice.error({
            title: "操作失败！",
            desc: '请检查表单是否填项正确！'
          });
          this.spinShow = true
        }
      })
    }
  },
}
</script>
<style lang="less" scoped>

</style>

<template>
  <Card class="articleConsultation" :icon="type === 1 ? 'ios-notifications' : 'ios-list-box-outline'" :title="type === 1 ? '通知' : '文章资讯'" :dis-hover="true" :bordered="false">
    <!-- 加载动画 -->
    <div style="position: relative;width: 100%;height: 200px;" v-if="chartLoading">
      <Spin fix>
        <Icon type="ios-loading" size="35" class="demo-spin-icon-load"></Icon>
        <div>加载中，请稍后......</div>
      </Spin>
    </div>
    <!-- 列表有数据 -->
    <div v-else-if="articleList && articleList.length > 0">
      <ul class="articleConsultation_list" :style="{'max-height': height - 250 + 'px',}">
        <li v-for="(item,index) in articleList" :key="index" @click="redirect(item)">
          <Avatar icon="ios-notifications" v-if="type === 1" class="avatar" :size="40"/>
          <div class="content" :style="type === 1 ? 'padding-left: 20px;' : 'padding-left: 0px;'">
            <h4>{{ item.title }}</h4>
            <p class="info">
              <span>{{item.orgName}}</span>|
              <span>{{item.publishedAt}}</span>
            </p>
          </div>
        </li>
      </ul>
      <div class="seeMore">
        <a v-if="seeMoreShow" @click="seeMore">查看更多</a>
      </div>
    </div>
    <!-- 列表无数据 -->
    <ys-empty v-else :title="type === 1 ? '暂无消息通知' : '暂无文章咨询推送'"></ys-empty>
    <!-- 查看详情 -->
    <ys-drawer v-model="modeShow" mask :width="90" title="消息/文章">
      <Detail :mid="detailID"></Detail>
    </ys-drawer>
  </Card>
</template>

<script>
import {mapGetters} from 'vuex'
import Detail from "@/views/home/articleConsultation/detail.vue";

export default {
  name: "notice",
  props: {
    type: { // 文章所属类型，1：通知；2：文章；默认文章
      type: [String, Number],
      default: 2
    }
  },
  data() {
    return {
      detailID: '',
      seeMoreShow: false,
      articleList: [],
      modeShow: false,
      chartLoading: false,
    }
  },
  components: {
    Detail
  },
  computed: {
    ...mapGetters(['userInfo', 'height'])
  },
  mounted() {
    this.getList()
  },
  methods: {
    async getList(limit = 10) {
      this.chartLoading = true
      this.articleList = []
      this.seeMoreShow = false
      // 暂时不显示子机构的文章
      await this.$get('/article/MyArticle', {orgId: this.userInfo.org_id, type: this.type, isTop: true, noPending: true, limit}).then((res) => {
        this.seeMoreShow = res.data.total > 10 && limit !== -1 ? true : false
        this.articleList = res.data.list
      })
      this.chartLoading = false
    },
    redirect(item) {
      this.modeShow = true
      this.detailID = item.id
    },
    seeMore() {
      this.getList(-1)
    }
  }

}
</script>

<style lang="less" scoped>
.seeMore {
  width: 100%;
  line-height: 50px;
  text-align: center;
}
.articleConsultation {
  overflow-y: auto;
  .ivu-card-body{
    padding: 0;
  }
  .articleConsultation_list {
    // max-height: 600px;
    overflow-y: auto;
    overflow-x: hidden;
    li {
      display: flex;
      list-style: none;
      padding: 10px;
      cursor: pointer;
      .avatar {
        flex-shrink: 0;
      }
      .content {
        padding-left: 15px;
        flex: 1;
        overflow: hidden;
        .info {
          padding-top: 3px;
          font-size: 14px;
          width: 100%;
          color: #999999;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
  }
}

.demo-spin-icon-load {
  animation: ani-demo-spin 1s linear infinite;
}

@keyframes ani-demo-spin {
  from {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
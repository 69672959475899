export default {
  /**
   * @description 配置显示在浏览器标签的title
   */
  title: '建筑行业安责险巡检管理云平台',
  shortTitle: '巡检管理云平台',


  /**
   * @description 默认首页的路由name值
   */
  default: 'home',

  /**
   * @description 登录页的路由name值
   */
  defaultLogin: 'login',

  /**
   * @description 接口前缀含域名端口协议
   */
  baseURL: '/web',
  /**
   * @description 接口前缀含域名端口协议
   */
  qqMapKey: 'OPJBZ-BGWCF-DDWJE-NXUYH-WQGR3-63FPN',

  /**
   * @description 默认最外层父路由
   */
  routerParent: 'main',

  /**
   * @description 无需登录的页面里有name
   */
  withOutLogin: ['login','enter','eventSign'],
}
